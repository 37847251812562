import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CommonService } from "../common.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-linkedin-profile",
  templateUrl: "app-linkedin-profile.component.html",
  styleUrls: ["app-linkedin-profile.component.css"],
})
export class LinkedinProfileComponent implements OnInit {
  linkedinId: string;
  @Input() showorhideProfileModal: boolean;
  @Input() showorhideModal: boolean;
  @Input() actor_urn: any;
  @Input() sub_type: any;
  @Output() onSuccess = new EventEmitter<any>();

  buttonSpinner = false;
  linkdinProfileArray = [];
  bussinessProfileArray = [];

  linkedInProfileForm = new FormGroup({
    select_profile: new FormControl("", Validators.required),
  });

  constructor(private common: CommonService, private toastr: ToastrService) {}

  ngOnInit(): void {}

  //Reusable function for toast notifications
  private showToast(message: string, type: "success" | "error") {
    const options = { closeButton: true, positionClass: "toast-bottom-center" };
    type === "success"
      ? this.toastr.success(message, "", options)
      : this.toastr.error(message, "", options);
  }

  //Get linkedin profile details
  getLinkedinProfile(linkedinId) {
    this.linkedinId = linkedinId;
    this.common.getSelectProfileOption(linkedinId).subscribe((res) => {
      for (let index = 0; index < res.body.length; index++) {
        const name = res.body[index].name.replace("_companyprofile_", "");
        res.body[index].select = name;
      }
      this.linkdinProfileArray = res.body;
      if (this.linkdinProfileArray.length > 1) {
        this.bussinessProfileArray = [this.linkdinProfileArray.shift()];
      } else {
        this.bussinessProfileArray = this.linkdinProfileArray;
      }
      if (this.linkdinProfileArray.length > 0) {
        if (this.sub_type == "Business") {
          this.showorhideProfileModal = true;
          this.linkedInProfileForm.setValue({
            select_profile: this.linkdinProfileArray[0].name,
          });
        } else {
          this.linkedInProfileForm.setValue({
            select_profile: this.bussinessProfileArray[0].name,
          });
          this.selectLinkdinProfile();
        }
      }
    });
  }

  //Select linkedin Profile
  selectLinkdinProfile() {
    this.buttonSpinner = true;
    if (this.showorhideProfileModal) {
      let data = this.linkdinProfileArray.filter((profile) =>
        profile.name.includes(this.linkedInProfileForm.value.select_profile)
      );
      this.actor_urn = data[0]["actor_urn"];
    } else {
      this.actor_urn = null;
    }

    if (this.linkedinId) {
      this.common.getLinkDinProfile(this.linkedinId).subscribe((res) => {
        if (res.status == 200) {
          this.common.deleteLinkDinProfile(this.linkedinId).subscribe((res) => {
            this.buttonSpinner = false;
            this.common
              .addLinkedinProfile(
                this.linkedInProfileForm.value.select_profile,
                this.linkedinId,
                this.actor_urn
              )
              .subscribe((res) => {
                if (res.status == 200) {
                  this.onSuccess.emit();
                } else {
                  this.showToast(
                    "Something went wrong! Please try again",
                    "error"
                  );
                }
              });
          });
        } else {
          this.common
            .addLinkedinProfile(
              this.linkedInProfileForm.value.select_profile,
              this.linkedinId,
              this.actor_urn
            )
            .subscribe((res) => {
              if (res.status == 200) {
                this.onSuccess.emit();
              } else {
                this.showToast(
                  "Something went wrong! Please try again",
                  "error"
                );
              }
            });
        }
      });
    } else {
      this.common
        .addLinkedinProfile(
          this.linkedInProfileForm.value.select_profile,
          this.linkedinId,
          this.actor_urn
        )
        .subscribe((res) => {
          this.buttonSpinner = false;
          if (res.status == 200) {
            this.onSuccess.emit();
          } else {
            this.showToast("Something went wrong! Please try again", "error");
          }
        });
    }
  }

  hideLinePopup() {
    this.showorhideProfileModal = false;
  }
}
