import { Component, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LinkedinProfileComponent } from "../app-linkedin-profile/app-linkedin-profile.component";
import { CommonService } from "../common.service";
import { AgencyService } from "./agency.service";

@Component({
  selector: "app-agency",
  templateUrl: "agency.component.html",
  styleUrls: ["agency.component.css"],
})
export class AgencyComponent implements OnInit {
  companySection = false;
  agencyStepOne: boolean = true;
  agencyStepTwo: boolean = false;
  agencyStepOneCompleted: boolean = false;
  agencyStepTwoCompleted: boolean = false;
  stepOne: boolean = true;
  stepTwo: boolean = false;
  stepThree: boolean = false;
  stepOneCompleted: boolean = false;
  stepTwoCompleted: boolean = false;
  stepThreeCompleted: boolean = false;
  buttonSpinner = false;
  allCountryList = [];
  heading = "Agency plan";
  description =
    "Please let us know your agency’s name to proceed with setting up your account";

  agencyForm = new FormGroup({
    agency_name: new FormControl("", Validators.required),
  });
  companyForm = new FormGroup({
    company_name: new FormControl("", Validators.required),
    email: new FormControl("", [
      Validators.required,
      this.strictEmailValidator(),
    ]),
  });

  linkedinReqCompleted: boolean = true;
  billingResCompleted: boolean = true;
  linkedinOtpReqCompleted: boolean = true;
  companyResCompleted: boolean = true;
  agencyResCompleted: boolean = true;
  linkedinConnectionData;
  linkedinStatus;
  headerHeading;
  showpopMsg = false;
  showVerificationPopMsg = false;
  showorhideModal;
  showorhideProfileModal;
  sub_type = "Business";
  companyId;
  billingId;
  linkedinId = null;
  doneDisable = true;
  firstId;
  actor_urn;
  billingForm = new FormGroup({
    line1: new FormControl("", Validators.required),
    line2: new FormControl(""),
    city: new FormControl("", Validators.required),
    state: new FormControl("", Validators.required),
    country: new FormControl("", Validators.required),
    postal_code: new FormControl("", Validators.required),
  });

  linkedInForm = new FormGroup({
    userid: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
  });
  linkedInotpForm = new FormGroup({
    otp: new FormControl("", Validators.required),
  });

  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    "name",
    "email",
    "created_at",
    "status",
    "edit_btn",
  ];

  @ViewChild(LinkedinProfileComponent)
  linkedInProfileComponent: LinkedinProfileComponent;

  editCompany = false;

  constructor(
    private agency: AgencyService,
    private common: CommonService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getAgencyDetails();
    this.getLinkdincredList();
    this.getcountryList();
    this.agency.linkdinId.subscribe((res) => {
      this.linkedinId = res;
      this.stepOne = true;
      this.companySection = true;
      this.heading = "Company details";
      this.description =
        "Enter the details of the company that you want to manage in Triggify";
    });
    this.agency.editCompanyDetails.subscribe((res) => {
      this.heading = "Company details";
      this.description =
        "Enter the details of the company that you want to manage in Triggify";
      this.editCompany = res;
      this.companySection = res;
    });
    this.agency.companyformData$.subscribe((data) => {
      if (data) {
        this.companyForm.patchValue(data);
      }
    });
    this.agency.billingformData$.subscribe((data) => {
      if (data) {
        this.billingForm.patchValue(data);
      }
    });
    this.agency.linkedinformData$.subscribe((data) => {
      if (data) {
        this.linkedInForm.patchValue({
          userid: data.userid,
        });
      }
    });
  }
  strictEmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return value && !emailPattern.test(value) ? { invalidEmail: true } : null;
    };
  }
  //Reusable function for toast notifications
  private showToast(message: string, type: "success" | "error") {
    const options = { closeButton: true, positionClass: "toast-bottom-center" };
    type === "success"
      ? this.toastr.success(message, "", options)
      : this.toastr.error(message, "", options);
  }

  //Agency form submission
  agencySubmit() {
    this.agencyResCompleted = false;
    this.agency.agencyUpdate(this.agencyForm.value).subscribe((res) => {
      this.agencyResCompleted = true;
      if (res.status == 200) {
        this.showToast("Added successfully.", "success");

        this.heading = "Connect accounts";
        this.description =
          "Add your portfolio companies and connect a LinkedIn account for each company";
        this.agencyStepOne = false;
        this.agencyStepOneCompleted = true;
        this.agencyStepTwo = true;
      } else {
        this.showToast("SomeThing went wrong try again", "error");
      }
    });
  }

  //get agency details
  getAgencyDetails() {
    this.agency.agencydetails().subscribe((res) => {
      if (res.status == 200) {
        if (res.body.agency_name == null) {
          this.agencyStepOne = true;
          this.agencyStepOneCompleted = false;
          this.agencyStepTwo = false;
          this.heading = "Agency plan";
          this.description =
            "Please let us know your agency’s name to proceed with setting up your account";
        } else {
          if (this.editCompany) {
          } else {
            this.heading = "Connect accounts";
            this.description =
              "Add your portfolio companies and connect a LinkedIn account for each company";
          }
          this.agencyStepOne = false;
          this.agencyStepOneCompleted = true;
          this.agencyStepTwo = true;
        }
      }
    });
  }

  //Get linkedin Id
  getLinkdincredList() {
    this.agency.llinkedincredList().subscribe((res) => {
      this.dataSource.data = res.body;
      if (res.body.length) {
        this.doneDisable = false;
        this.firstId = res.body[0].id;
        localStorage.setItem("linkedinId", res.body[0].id);
      } else {
        this.doneDisable = true;
      }
    });
  }

  //add a new company
  addnewCompany() {
    this.stepOne = true;
    this.companySection = true;
    this.heading = "Company details";
    this.description =
      "Enter the details of the company that you want to manage in Triggify";
    this.linkedinId = null;
    this.agencyForm.reset();
    this.billingForm.reset();
    this.companyForm.reset();
  }

  //add company name
  companyNameAdd() {
    this.companyResCompleted = false;
    if (this.linkedinId) {
      this.agency
        .updatecompanyresponse(this.companyForm.value, this.linkedinId)
        .subscribe((res) => {
          this.companyResCompleted = true;
          this.companyId = res.body.id;
          this.heading = "Location details";
          this.description =
            "This location must be the same as the location from where you normally access the LinkedIn account that is an admin for this companies LinkedIn page. We will provide you with a free proxy to protect your IP address.";
        });
    } else {
      this.agency.createCompany(this.companyForm.value).subscribe((res) => {
        this.companyId = res.body.message.id;
        this.companyResCompleted = true;
        this.heading = "Location details";
        this.description =
          "This location must be the same as the location from where you normally access the LinkedIn account that is an admin for this companies LinkedIn page. We will provide you with a free proxy to protect your IP address.";
      });
    }

    this.stepOne = false;
    this.stepTwo = true;
    this.stepOneCompleted = true;
  }

  //Get country list
  getcountryList() {
    this.common.getAllCountry().subscribe((res) => {
      if (res.status == 200) {
        this.allCountryList = res.body.message.country_code;
      }
    });
  }

  //Create billing
  createBillDetails() {
    this.buttonSpinner = true;
    this.billingResCompleted = false;
    if (this.linkedinId) {
      this.common
        .onUpdateBill(this.billingForm.value, this.linkedinId)
        .subscribe((res) => {
          this.buttonSpinner = false;
          this.billingResCompleted = true;
          if (res.status == 200) {
            this.showToast("Added successfully.", "success");

            this.heading = "Connect LinkedIn";
            this.description =
              "Connect a LinkedIn account that is an admin for the companies LinkedIn page";
            this.billingId = res.body.id;
            this.stepOne = false;
            this.stepTwo = false;
            this.stepThree = true;
            this.stepOneCompleted = true;
            this.stepTwoCompleted = true;
            this.heading = "Connect LinkedIn";
            this.description =
              "Connect a LinkedIn account that is an admin for the companies LinkedIn page";
          } else {
            this.showToast(res.error.message, "error");
          }
        });
    } else {
      this.common.onCreateBill(this.billingForm.value).subscribe((res) => {
        this.buttonSpinner = false;
        this.billingResCompleted = true;
        if (res.status == 200) {
          this.showToast("Added successfully.", "success");

          this.billingId = res.body.id;
          this.stepOne = false;
          this.stepTwo = false;
          this.stepThree = true;
          this.stepOneCompleted = true;
          this.stepTwoCompleted = true;
          this.heading = "Connect LinkedIn";
          this.description =
            "Connect a LinkedIn account that is an admin for the companies LinkedIn page";
        } else {
          this.showToast(res.error.message, "error");
        }
      });
    }
  }

  // linkdin connect step
  connectToLinkedin() {
    this.showpopMsg = true;
    this.buttonSpinner = true;
    this.linkedinReqCompleted = false;
    let obj = {
      billing_id: this.billingId,
      response_email_id: this.companyId,
    };
    let bodyData = { ...obj, ...this.linkedInForm.value };
    if (this.linkedinId == null) {
      this.common.onConnectToLinkedin(bodyData).subscribe((res) => {
        this.linkedinConnectionData = res.body;
        this.buttonSpinner = false;
        this.linkedinReqCompleted = true;
        if (res.status == 200) {
          this.linkedinId = res.body.linkedin;
          if (res.body.message == "added") {
            this.showToast(res.body.message, "success");
            this.linkedinId = res.body.linkedin;
            this.linkedInProfileComponent.getLinkedinProfile(res.body.linkedin);
            this.showpopMsg = false;
          } else if (
            res.body.message ==
            "Incorrect Username or Password, Please check it"
          ) {
            this.showpopMsg = false;
            this.showToast(
              "We were not able to connect your account. Please double-check your credentials and try again.",
              "error"
            );

            this.spinner.hide();
            this.showorhideModal = false;
          } else if (
            res.body.message.includes(
              "Failed to refresh IP or reached max retry limit"
            )
          ) {
            this.showpopMsg = false;
            this.showToast(
              "Connection failed: LinkedIn is giving you a CAPTCHA because you logged in/out too many times. Please try again in 24 hours or use a different LinkedIn account.",
              "error"
            );

            this.spinner.hide();
            this.showorhideModal = false;
          } else if (
            res.body.message.includes(
              "phone number is required, please add phone number on your account"
            )
          ) {
            this.showpopMsg = false;
            this.showToast(
              "Please add a phone number to your LinkedIn account and try again.",
              "error"
            );

            this.spinner.hide();
            this.showorhideModal = false;
          } else if (res.body.message.includes("Your account is restricted")) {
            this.showpopMsg = false;
            this.showToast(
              "Your account is restricted. Please activate your LinkedIn account and try again.",
              "error"
            );

            this.spinner.hide();
            this.showorhideModal = false;
          } else if (
            res.body.message.includes("failed to generate captcha token")
          ) {
            this.showpopMsg = false;
            this.showToast(
              "Oops! We couldn't generate the captcha token. Please try again.",
              "error"
            );

            this.spinner.hide();
            this.showorhideModal = false;
          } else {
            this.showpopMsg = false;
            this.showToast(res.body.message, "success");

            this.spinner.hide();
            this.showorhideModal = true;
          }
        } else {
          this.linkedinId = res.error.linkedin;
          this.showpopMsg = false;
          if (
            res.error.message ==
            "'userid' - ErrorDetail(string='linkedin credentials with this userid already exists.', code='unique')"
          ) {
            this.showToast(
              "Your LinkedIn profile is already connected to an existing Triggify account. Please login to your existing account.",
              "error"
            );
          } else if (
            res.error.message ==
            "Incorrect Username or Password, Please check it"
          ) {
            this.showToast(
              "We were not able to connect your account. Please double check your credentials and try again.",
              "error"
            );
          } else if (
            res.error.message ==
            "previous login script is running, please try again after 5 minutes"
          ) {
            this.showToast(
              "previous login script is running, please try again after 5 minutes",
              "error"
            );
          } else if (res.error.message == "failed to login") {
            this.showToast("failed to login.", "error");
          } else if (
            res.error.message.includes("ERR_TUNNEL_CONNECTION_FAILED")
          ) {
            this.showToast("Proxy Failed Please Try after sometime", "error");
          } else if (
            res.error.message.includes("Agency user linkedin account limit")
          ) {
            this.showToast(res.error.message, "error");
          } else {
            this.showToast(
              "Something Went wrong Please Try again or try after sometime",
              "error"
            );
          }
          this.showorhideModal = false;
          this.linkedinConnectionStatus();
        }
      });
    } else {
      this.common
        .onUpdateToLinkedinId(bodyData, this.linkedinId)
        .subscribe((res) => {
          this.linkedinConnectionData = res.body;

          this.buttonSpinner = false;
          this.linkedinReqCompleted = true;
          if (res.status == 200) {
            if (res.body.message == "added") {
              this.showToast(res.body.message, "success");

              this.linkedInProfileComponent.getLinkedinProfile(this.linkedinId);
              this.showpopMsg = false;
            } else if (
              res.body.message ==
              "Incorrect Username or Password, Please check it"
            ) {
              this.showpopMsg = false;
              this.showToast(
                "We were not able to connect your account. Please double-check your credentials and try again.",
                "error"
              );

              this.spinner.hide();
              this.showorhideModal = false;
            } else if (
              res.body.message.includes(
                "Failed to refresh IP or reached max retry limit"
              )
            ) {
              this.showpopMsg = false;
              this.showToast(
                "Connection failed: LinkedIn is giving you a CAPTCHA because you logged in/out too many times. Please try again in 24 hours or use a different LinkedIn account.",
                "error"
              );

              this.spinner.hide();
              this.showorhideModal = false;
            } else if (
              res.body.message.includes(
                "phone number is required, please add phone number on your account"
              )
            ) {
              this.showpopMsg = false;
              this.showToast(
                "Connection failed: LinkedIn is giving you a CAPTCHA because you logged in/out too many times. Please try again in 24 hours or use a different LinkedIn account.",
                "error"
              );

              this.spinner.hide();
              this.showorhideModal = false;
            } else if (
              res.body.message.includes("Your account is restricted")
            ) {
              this.showpopMsg = false;
              this.showToast(
                "Your account is restricted. Please activate your LinkedIn account and try again.",
                "error"
              );

              this.spinner.hide();
              this.showorhideModal = false;
            } else if (
              res.body.message.includes("failed to generate captcha token")
            ) {
              this.showpopMsg = false;
              this.showToast(
                "Oops! We couldn't generate the captcha token. Please try again.",
                "error"
              );

              this.spinner.hide();
              this.showorhideModal = false;
            } else {
              this.showpopMsg = false;
              this.showToast(res.body.message, "success");

              this.spinner.hide();
              this.showorhideModal = true;
            }
          } else {
            this.showpopMsg = false;
            if (
              res.error.message ==
              "'userid' - ErrorDetail(string='linkedin credentials with this userid already exists.', code='unique')"
            ) {
              this.showToast(
                "Your LinkedIn profile is already connected to an existing Triggify account. Please login to your existing account.",
                "error"
              );
            } else if (
              res.error.message ==
              "previous login script is running, please try again after 5 minutes"
            ) {
              this.showToast(
                "previous login script is running, please try again after 5 minutes",
                "error"
              );
            } else if (
              res.error.message ==
              "Incorrect Username or Password, Please check it"
            ) {
              this.showToast(
                "We were not able to connect your account. Please double check your credentials and try again.",
                "error"
              );
            } else if (res.error.message == "failed to login") {
              this.showToast("failed to login.", "error");
            } else if (
              res.error.message.includes("ERR_TUNNEL_CONNECTION_FAILED")
            ) {
              this.showToast("Proxy Failed Please Try after sometime", "error");
            } else if (
              res.error.message.includes("Agency user linkedin account limit")
            ) {
              this.showToast(res.error.message, "error");
            } else {
              this.showToast(
                "Something Went wrong Please Try again or try after sometime",
                "error"
              );
            }
            this.showorhideModal = false;
            this.linkedinConnectionStatus();
          }
        });
    }
  }

  //Verify Linkedin Id
  verifyLinkedin() {
    this.buttonSpinner = true;
    this.linkedinOtpReqCompleted = false;
    this.showVerificationPopMsg = true;
    delete this.linkedinConnectionData.message;
    this.linkedinConnectionData["pin"] = this.linkedInotpForm.value.otp;
    this.linkedinConnectionData["linkedin"] = this.linkedinId;
    this.common
      .linkedinPinVerify(this.linkedinConnectionData)
      .subscribe((res) => {
        this.buttonSpinner = false;
        this.linkedinOtpReqCompleted = true;
        if (res.status == 200) {
          this.showorhideModal = false;
          this.showVerificationPopMsg = false;
          this.linkedInProfileComponent.getLinkedinProfile(this.linkedinId);
        } else {
          this.showToast(
            "We were not able to connect. You most likely have entered the wrong verification code. Please try again.",
            "error"
          );
        }
      });
  }

  //Get linkedin connection status
  linkedinConnectionStatus() {
    this.common.getLinkedinProfile(this.linkedinId).subscribe((res) => {
      if (res.body.cookie == null) {
        this.linkedinStatus = false;
      } else {
        this.linkedinStatus = true;
      }
    });
  }

  //Edit company account
  editCompanyAccount(id) {
    this.linkedinId = id;
    this.editCompany = true;
    this.stepOne = true;
    this.companySection = true;
    this.heading = "Company details";
    this.description =
      "Enter the details of the company that you want to manage in Triggify";
    this.agency.updateFormData(id);
  }

  //Get Billing Details
  billingDetails(id) {
    this.common.getBillingDetails(id).subscribe((res) => {
      this.spinner.hide();

      if (res.status == 200) {
        if (res.body.message.billing_details.name == null) {
          this.billingForm.setValue({
            line1: res.body.message.billing_details.line1,
            line2: res.body.message.billing_details.line2,
            city: res.body.message.billing_details.city,
            country: res.body.message.billing_details.country,
            postal_code: res.body.message.billing_details.postal_code,
            state: res.body.message.billing_details.state,
          });
        } else {
          let array = res.body.message.billing_details.name.split(" ");
          this.billingForm.setValue({
            line1: res.body.message.billing_details.line1,
            line2: res.body.message.billing_details.line2,
            city: res.body.message.billing_details.city,
            country: res.body.message.billing_details.country,
            postal_code: res.body.message.billing_details.postal_code,
            state: res.body.message.billing_details.state,
          });
        }
      }
    });
  }

  //Navigate to agency dashboard
  done() {
    this.router.navigate(["agency-dashbaord"]);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  hidePopup() {
    this.showorhideModal = false;
  }

  handleSuccess() {
    // Handle the success response here
    this.companySection = false;
    this.stepOne = true;
    this.stepTwo = false;
    this.stepThree = false;
    this.stepOneCompleted = false;
    this.stepTwoCompleted = false;
    this.stepThreeCompleted = false;
    this.agencyStepOne = false;
    this.agencyStepOneCompleted = true;
    this.agencyStepTwo = true;
    this.getLinkdincredList();
    this.showorhideProfileModal = false;
    this.linkedinId = null;
  }
}
