import { Injectable } from "@angular/core";
import * as mixpanel from "mixpanel-browser";

@Injectable({
  providedIn: "root",
})
export class MixpanelService {
  userId;

  constructor() {}
  //For tracking user interactions

  /**
   * Push new action to mixpanel.
   *
   * @param id Name of the action to track.
   * @param [action={}] Actions object with custom properties.
   */
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }
}
