import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LocalService {
  triggerName = new Subject<any>();
  triggerId = new Subject<number>();
  triggerCount = new Subject<number>();
  model = new Subject<any>();

  constructor() {}

  //This service is built to interact with local storage to retrieve, update, remove keys such as tokens

  setlocalstorage(Key, value) {
    localStorage.setItem(Key, value);
  }
  getlocalstorage(key) {
    return localStorage.getItem(key);
  }
  removelocalstorage(key) {
    return localStorage.removeItem(key);
  }

  storeAccessToken(jwt: string) {
    localStorage.setItem("access_token", jwt);
  }

  storeRefreshToken(jwt: string) {
    localStorage.setItem("refresh_token", jwt);
  }

  getAccessToken() {
    return localStorage.getItem("token");
  }

  getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }

  storeRefreshTokenExpiry(jwt: string) {
    localStorage.setItem("refresh_token_exp", jwt["exp"]);
  }

  getAccessTokenExpTime(): any {
    return localStorage.getItem("access_token_exp");
  }

  getRefreshTokenExpTime(): any {
    return localStorage.getItem("refresh_token_exp");
  }
}

