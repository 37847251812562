import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ConfirmedValidator } from "../../shared/password-validator/confirmed.validator";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "forgot-password.component.html",
  styleUrls: ["forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  passwordResetPage: boolean = true;
  resetConfirmForm;
  buttonSpinner = false;

  passwordResetForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
  });

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  //Initialize the form
  ngOnInit(): void {
    this.resetConfirmForm = this.fb.group(
      {
        otp: ["", Validators.required],
        new_password1: ["", [Validators.required, Validators.minLength(6)]],
        new_password2: [""],
      },
      {
        validator: ConfirmedValidator("new_password1", "new_password2"),
      }
    );
  }

  //Forget password request is handled
  passwordresetRequest() {
    this.buttonSpinner = true;
    this.auth
      .passwordReset(this.passwordResetForm.value.email)
      .subscribe((res) => {
        this.buttonSpinner = false;
        if (res.status == 200) {
          this.passwordResetPage = false;
          this.toastr.success("Password reset OTP sent successfully", "", {
            closeButton: false,
            positionClass: "toast-bottom-center",
          });
        } else {
          this.toastr.error(res.error.message, "", {
            closeButton: false,
            positionClass: "toast-bottom-center",
          });
        }
      });
  }

  //To reset the form controls
  get f() {
    return this.resetConfirmForm.controls;
  }

  //To submit and verify OTP
  otpSubmit() {
    this.buttonSpinner = true;
    this.auth
      .passwordresetotp(
        this.resetConfirmForm.value,
        this.passwordResetForm.value.email
      )
      .subscribe((res) => {
        this.buttonSpinner = false;
        if (res.status == 400) {
          this.toastr.error(res.error.message, "", {
            closeButton: false,
            positionClass: "toast-bottom-center",
          });
        } else {
          this.toastr.success("Password reset successfully!", "", {
            closeButton: false,
            positionClass: "toast-bottom-center",
          });
          this.router.navigate([""]);
        }
      });
  }
}
