import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { LocalService } from "../../shared/service/local.service";
import { ToastrService } from "ngx-toastr";
import { TriggerService } from "../../pages/trigger/trigger.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CommonService } from "../../pages/common.service";
import { noUrlValidator } from "../../shared/url-validator/url.validator";
import { BillingService } from "../../pages/billing/billing.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  accessToken;
  title;
  path;
  showOrHide = true;
  isSub;
  basicFree;
  triggerIdValue;
  showorhideModal = false;
  addConditionField_1 = false;
  addConditionField_2 = false;
  step_1 = true;
  step_2 = false;
  step_3 = false;
  step_4 = false;
  step_5 = false;
  step_reaction = false;
  step_1_complted = false;
  step_2_complted = false;
  step_3_complted = false;
  step_4_complted = false;
  step_5_complted = false;
  step_reaction_complted = false;
  flag: boolean;
  keywordValue;
  editDelete;
  linkdinProfileArray = [];
  triggerName;
  count;
  modelStyle;
  showLinkdinProfileStep = false;
  profileValue;
  triggerPath;
  triggerTypeValue;
  profiletriggerKeywordName;
  bussinessSelectProfileArray;
  plan_type;

  showHideActivityfeed;
  reactionArray = [];
  actual_reaction_value;
  stepNumer = 5;
  activityStepNumber = 3;
  linkedinId;

  // Form configurations
  triggerNameForm = new FormGroup({
    trigger_name: new FormControl("", Validators.required),
  });
  keywordForm = new FormGroup({
    keyword: new FormControl("", [Validators.required, noUrlValidator()]),
    keyword2: new FormControl("", [noUrlValidator()]),
    keyword3: new FormControl("", [noUrlValidator()]),
  });
  profileForm = new FormGroup({
    keyword: new FormControl("", [
      Validators.required,
      Validators.pattern(
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
      ),
    ]),
    keyword2: new FormControl("", [noUrlValidator()]),
    keyword3: new FormControl("", [noUrlValidator()]),
  });
  monitorForm = new FormGroup({
    preference: new FormControl("", Validators.required),
  });
  activityForm = new FormGroup({
    set_activity: new FormControl("", Validators.required),
  });
  linkedInProfileForm = new FormGroup({
    select_profile: new FormControl("", Validators.required),
    actor_urn: new FormControl(""),
  });

  constructor(
    private location: Location,
    private toastr: ToastrService,
    private router: Router,
    private local: LocalService,
    private trigger: TriggerService,
    private common: CommonService,
    private billing: BillingService
  ) {}

  ngOnInit() {
    this.linkedinId = localStorage.getItem("linkedinId");
    this.editDelete = localStorage.getItem("edit_trugger");
    this.title = localStorage.getItem("trigger_Name");
    this.triggerIdValue = localStorage.getItem("id");
    this.count = localStorage.getItem("count");
    this.common.linkedinList$.subscribe((res) => {
      this.plan_type = localStorage.getItem("sub_type");
      this.seletedProfileListLogic(res);
    });
    this.common.linkedinProfile$.subscribe((res) => {
      this.plan_type = localStorage.getItem("sub_type");
      this.seletedProfilevalue(res);
    });
    this.getSubscriptionStatus();
    this.getReactionType();
    this.basicFree = localStorage.getItem("headerHeading");
    this.accessToken = this.local.getAccessToken();
    this.local.triggerName.subscribe((res) => {
      this.title = res;
    });
    this.local.triggerId.subscribe((res) => {
      this.triggerIdValue = res;
      this.getTrigerdetails();
    });
    this.local.triggerCount.subscribe((res) => {
      this.count = 0;
      this.count = res;
    });
    this.trigger.linkedinProfileShowHide.subscribe((res) => {
      if (res == false) {
        this.getSubscriptionStatus();
      } 
    });
  }

  //Reusable function for toast notifications
  private showToast(message: string, type: "success" | "error") {
    const options = { closeButton: true, positionClass: "toast-bottom-center" };
    type === "success"
      ? this.toastr.success(message, "", options)
      : this.toastr.error(message, "", options);
  }

  //To get current subscribed plan status
  getSubscriptionStatus() {
    this.billing.currentPlan().subscribe((res) => {
      this.plan_type = res.body.message.description;
      if (
        res.body.message.description == "Business" ||
        res.body.message.description.includes("Agency")
      ) {
        this.showLinkdinProfileStep = true;
      } else {
        this.showLinkdinProfileStep = false;
      }
    });
  }

  // Linkedin Profile list
  seletedProfileListLogic(res) {
    this.linkdinProfileArray = [];
    for (let index = 0; index < res.body.length; index++) {
      const arr = res.body[index].name.split("_", 3);
      this.linkdinProfileArray.push({
        name: arr[2],
        datatopass: res.body[index].name,
        actor_urn: res.body[index].actor_urn,
      });
    }
  }
  // To set linkdinProfilEForm based on plan type
  seletedProfilevalue(res) {
    this.bussinessSelectProfileArray = [];
    if (this.plan_type == "Business" || this.plan_type.includes("Agency")) {
      this.bussinessSelectProfileArray.push(this.linkdinProfileArray[0]);
      this.profileValue = res.body.profile;
      if (res.body.profile.includes("_personalprofile_") == false) {
        let name = res.body.profile.replace("_companyprofile_", "");
        this.bussinessSelectProfileArray.push({
          name: name,
          datatopass: res.body.profile,
          actor_urn: res.body.actor_urn,
        });
      }
      this.linkedInProfileForm.setValue({
        select_profile: this.bussinessSelectProfileArray[0].datatopass,
        actor_urn: this.bussinessSelectProfileArray[0].actor_urn,
      });
    } else {
      this.linkedInProfileForm.setValue({
        select_profile: res.body.profile,
        actor_urn: res.body.actor_urn,
      });
    }
  }

  ngDoCheck(): void {
    this.showOrHide = false;
    this.basicFree = localStorage.getItem("headerHeading");
    this.isSub = localStorage.getItem("susbcribe");
    this.path = this.location.path();
    this.triggerPath = location.pathname.split("/")[1];
    if (this.path == "/home") {
      this.showOrHide = true;
    }
    if (this.triggerPath == "stripe") {
      this.title = "Add Payment Method";
      this.showOrHide = false;
    }
    if (this.triggerPath == "trigger") {
      this.title = "Triggers";
      this.showOrHide = false;
    }
    if (this.triggerPath == "account") {
      this.title = "My Account";
      this.showOrHide = false;
    }
    if (this.triggerPath == "billing") {
      this.title = "Subscription & Billing";
      this.showOrHide = false;
    }
    if (this.triggerPath == "billing") {
      this.title = "Subscription & Billing";
      this.showOrHide = false;
    }
    if (this.triggerPath == "invite") {
      this.title = "Invite peers";
      this.showOrHide = false;
    }
    if (this.triggerPath == "settings") {
      this.title = "Invite peers";
      this.showOrHide = false;
    }

    if (this.triggerPath == `dashboard`) {
      this.showOrHide = true;
    }
    if (this.path == `/dashboard/view_all`) {
      this.showOrHide = true;
      this.title = "View All";
    }
  }
  //logout
  logout() {
    this.local.setlocalstorage("islogin", false);
    this.local.removelocalstorage("access_token");
    this.accessToken = this.local.getAccessToken();
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate([""]);
    this.showToast("Logged out successfully.", "success");
  }
  //deleting the triggers
  deletetrigger() {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.accessToken}`);

    let formdata = new FormData();
    formdata.append("id", this.triggerIdValue);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: formdata,
    };

    fetch(
      // "https://production-api.triggify.io/api/account/add-triggers",
      "https://api-dev.triggify.io/api/account/add-triggers",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        this.trigger.showorhideModal.next(false);
        this.showToast("Trigger deleted successfully.", "success");
        this.router.navigate(["dashboard/view_all"]);
      })
      .catch((error) => {
        this.showToast("Something went wrong try again!", "error");
      });
  }
  //edit triggers
  editTrigger() {
    this.showorhideModal = true;
    this.getTrigerdetails();
    document.getElementById("z-navbar").style.zIndex = "10";
    document.getElementById("z-sidebar").style.zIndex = "1";
  }
  //close popup
  hidepopup() {
    this.showorhideModal = false;
  }

  getTrigerdetails() {
    this.trigger.onGetinvidualTrigger(this.triggerIdValue).subscribe((res) => {
      this.step_1 = true;
      this.step_2 = false;
      this.step_3 = false;
      this.step_4 = false;
      this.step_5 = false;
      this.step_reaction = false;
      this.step_reaction_complted = false;
      this.step_1_complted = false;
      this.step_2_complted = false;
      this.step_3_complted = false;
      this.step_4_complted = false;
      this.step_5_complted = false;
      if (res.body.set_activity == "Monitor matching posts") {
        this.showHideActivityfeed = false;
        this.activityForm.setValue({
          set_activity: res.body.set_activity,
        });
        this.stepNumer = 5;
        this.activityStepNumber = 3;
        this.actual_reaction_value = "like all posts";
      } else {
        this.showHideActivityfeed = true;
        this.activityForm.setValue({
          set_activity: "like all posts",
        });
        this.actual_reaction_value = res.body.set_activity;
        this.stepNumer = 6;
        this.activityStepNumber = 4;
      }

      if (res.body.keyword.length == 0) {
        this.keywordValue = res.body.url;
        this.triggerTypeValue = "profile";
        this.profileForm.setValue({
          keyword: this.keywordValue,
          keyword2: res.body.keyword2,
          keyword3: res.body.keyword3,
        });
      } else {
        this.keywordValue = res.body.keyword;
        this.triggerTypeValue = "keyword";
        this.keywordForm.setValue({
          keyword: this.keywordValue,
          keyword2: res.body.keyword2,
          keyword3: res.body.keyword3,
        });
      }
      if (res.body.keyword2.length > 0) {
        this.addConditionField_1 = true;
      } else {
        this.addConditionField_1 = false;
      }
      if (res.body.keyword3.length > 0) {
        this.addConditionField_2 = true;
      } else {
        this.addConditionField_2 = false;
      }
      this.triggerNameForm.setValue({
        trigger_name: res.body.trigger_name,
      });

      this.monitorForm.setValue({
        preference: res.body.preference,
      });

      this.linkedInProfileForm.setValue({
        select_profile: res.body.select_profile,
        actor_urn: res.body.actor_urn,
      });
    });
  }

  addConditionKeyword() {
    this.addConditionField_1 = true;
  }

  addConditionKeywordTwo() {
    this.addConditionField_2 = true;
  }
  //Handle stepper form submissions
  onSubmitStep_1() {
    if (this.triggerTypeValue == "profile") {
      if (this.profileForm.value.keyword.endsWith("/")) {
        this.profiletriggerKeywordName =
          this.profileForm.value.keyword.split("/")[
            this.profileForm.value.keyword.split("/").length - 2
          ];
      } else {
        this.profiletriggerKeywordName =
          this.profileForm.value.keyword.split("/")[
            this.profileForm.value.keyword.split("/").length - 1
          ];
      }
    }
    this.step_1_complted = true;
    this.step_2 = true;
  }
  onSubmitStep_2() {
    if (this.triggerTypeValue == "profile") {
      if (this.activityForm.value.set_activity == "Monitor matching posts") {
        this.showHideActivityfeed = false;
        this.activityStepNumber = 3;
        if (this.showLinkdinProfileStep) {
          this.step_2_complted = true;
          this.step_3 = true;
        } else {
          this.updateTrigger();
        }
      } else {
        this.activityStepNumber = 4;
        this.showHideActivityfeed = true;
        this.step_3_complted = false;
        this.step_reaction = true;
        this.step_2_complted = true;
        this.step_3 = false;
        this.activityForm.setValue({
          set_activity: this.actual_reaction_value,
        });
      }
    } else {
      this.step_2_complted = true;
      this.step_3 = true;
    }
  }
  onSubmitStep_3() {
    this.step_3_complted = true;
    this.step_4 = true;
  }
  onSubmitStep_4() {
    if (this.activityForm.value.set_activity == "Monitor matching posts") {
      this.showHideActivityfeed = false;
      this.stepNumer = 5;
      if (this.showLinkdinProfileStep) {
        this.step_4_complted = true;
        this.step_5 = true;
      } else {
        this.updateTrigger();
      }
    } else {
      this.stepNumer = 6;
      this.showHideActivityfeed = true;
      this.step_4_complted = true;
      this.step_reaction = true;
      this.activityForm.setValue({
        set_activity: this.actual_reaction_value,
      });
    }
  }

  onSubmitStep_reaction() {
    if (this.triggerTypeValue == "profile") {
      if (this.showLinkdinProfileStep) {
        this.step_reaction_complted = true;
        this.step_3 = true;
      } else {
        this.updateTrigger();
      }
    } else {
      if (this.showLinkdinProfileStep) {
        this.step_reaction_complted = true;
        this.step_5 = true;
      } else {
        this.updateTrigger();
      }
    }
  }

  onBack_2() {
    this.step_2 = false;
    this.step_1 = true;
    this.step_1_complted = false;
  }
  onBack_3() {
    if (this.triggerTypeValue == "keyword") {
      this.step_3 = false;
      this.step_2 = true;
      this.step_2_complted = false;
    } else {
      if (this.showHideActivityfeed) {
        this.step_3 = false;
        this.step_reaction = true;
        this.step_reaction_complted = false;
      } else {
        this.step_3 = false;
        this.step_2 = true;
        this.step_2_complted = false;
      }
    }
  }
  onBack_4() {
    this.step_4 = false;
    this.step_3 = true;
    this.step_3_complted = false;
  }
  onBack_activity() {
    if (this.triggerTypeValue == "profile") {
      this.step_reaction = false;
      this.step_2 = true;
      this.step_2_complted = false;
      this.activityForm.setValue({
        set_activity: "like all posts",
      });
    } else {
      this.step_reaction = false;
      this.step_4 = true;
      this.step_4_complted = false;
      this.activityForm.setValue({
        set_activity: "like all posts",
      });
    }
  }
  onBack_5() {
    this.step_5 = false;
    this.step_4 = true;
    this.step_4_complted = false;
  }

  //Updating the trigger
  updateTrigger() {
    if (this.showLinkdinProfileStep) {
      let data = this.bussinessSelectProfileArray.filter((profile) =>
        profile.datatopass.includes(
          this.linkedInProfileForm.value.select_profile
        )
      );
      this.linkedInProfileForm.patchValue({
        actor_urn: data[0].actor_urn,
      });
    } else {
      this.linkedInProfileForm.patchValue({
        actor_urn: null,
      });
    }
    this.step_5_complted = true;
    this.step_5 = true;
    this.isURL(this.keywordForm.value.keyword);
    if (this.triggerTypeValue == "profile") {
      let bodyData = new FormData();
      bodyData.append("trigger_name", this.profiletriggerKeywordName);
      bodyData.append("set_activity", this.activityForm.value.set_activity);
      bodyData.append("keyword", "");
      bodyData.append("url", this.profileForm.value.keyword);
      bodyData.append("keyword2", this.profileForm.value.keyword2);
      bodyData.append("keyword3", this.profileForm.value.keyword3);
      bodyData.append("preference", this.monitorForm.value.preference);
      bodyData.append("id", this.triggerIdValue);
      bodyData.append(
        "select_profile",
        this.linkedInProfileForm.value.select_profile
      );
      bodyData.append("actor_urn", this.linkedInProfileForm.value.actor_urn);
      this.trigger.updateTriggerData(bodyData).subscribe((res) => {
        if (res.status == 200) {
          this.trigger.showorhideModal.next(false);
          this.showToast("Trigger Updated successfully!", "success");
          this.triggerNameForm.reset();
          this.profileForm.reset();
          this.monitorForm.reset();
          this.linkedInProfileForm.reset();
          this.activityForm.reset();
          this.showorhideModal = false;
          this.step_1 = true;
          this.step_2 = false;
          this.step_3 = false;
          this.step_4 = false;
          this.step_5 = false;
          this.step_reaction = false;
          this.step_reaction_complted = false;
          this.step_1_complted = false;
          this.step_2_complted = false;
          this.step_3_complted = false;
          this.step_4_complted = false;
          this.step_5_complted = false;
        } else {
          this.trigger.showorhideModal.next(false);
          if (
            res.error.message.includes(
              "Exception- duplicate key value violates unique"
            )
          ) {
            this.showToast(
              "You already have an active trigger with this name. Please change the trigger name to activate it.",
              "error"
            );
          } else {
            this.showToast(res.error.message, "error");
          }
          this.triggerNameForm.reset();
          this.profileForm.reset();
          this.monitorForm.reset();
          this.linkedInProfileForm.reset();
          this.activityForm.reset();
          this.showorhideModal = false;
          this.step_1 = true;
          this.step_2 = false;
          this.step_3 = false;
          this.step_4 = false;
          this.step_5 = false;
          this.step_reaction = false;
          this.step_reaction_complted = false;
          this.step_1_complted = false;
          this.step_2_complted = false;
          this.step_3_complted = false;
          this.step_4_complted = false;
          this.step_5_complted = false;
        }
      });
    } else {
      let bodyData = new FormData();
      bodyData.append("trigger_name", this.triggerNameForm.value.trigger_name);
      bodyData.append("set_activity", this.activityForm.value.set_activity);
      bodyData.append("keyword", this.keywordForm.value.keyword);
      bodyData.append("url", "");
      bodyData.append("keyword2", this.keywordForm.value.keyword2);
      bodyData.append("keyword3", this.keywordForm.value.keyword3);
      bodyData.append("preference", this.monitorForm.value.preference);
      bodyData.append("id", this.triggerIdValue);
      bodyData.append(
        "select_profile",
        this.linkedInProfileForm.value.select_profile
      );
      bodyData.append("actor_urn", this.linkedInProfileForm.value.actor_urn);
      this.trigger.updateTriggerData(bodyData).subscribe((res) => {
        if (res.status == 200) {
          this.trigger.showorhideModal.next(false);
          this.showToast("Trigger Updated successfully!", "success");

          this.triggerNameForm.reset();
          this.keywordForm.reset();
          this.monitorForm.reset();
          this.linkedInProfileForm.reset();
          this.activityForm.reset();
          this.showorhideModal = false;
          this.step_1 = true;
          this.step_2 = false;
          this.step_3 = false;
          this.step_4 = false;
          this.step_5 = false;
          this.step_1_complted = false;
          this.step_2_complted = false;
          this.step_3_complted = false;
          this.step_4_complted = false;
          this.step_5_complted = false;
          this.step_reaction = false;
          this.step_reaction_complted = false;
        } else {
          this.trigger.showorhideModal.next(false);
          if (
            res.error.message.includes(
              "Exception- duplicate key value violates unique"
            )
          ) {
            this.showToast(
              "You already have an active trigger with this name. Please change the trigger name to activate it.",
              "error"
            );
          } else {
            this.showToast(res.error.message, "error");
          }
          this.triggerNameForm.reset();
          this.keywordForm.reset();
          this.monitorForm.reset();
          this.linkedInProfileForm.reset();
          this.activityForm.reset();
          this.showorhideModal = false;
          this.step_1 = true;
          this.step_2 = false;
          this.step_3 = false;
          this.step_4 = false;
          this.step_5 = false;
          this.step_1_complted = false;
          this.step_2_complted = false;
          this.step_3_complted = false;
          this.step_4_complted = false;
          this.step_5_complted = false;
          this.step_reaction = false;
          this.step_reaction_complted = false;
        }
      });
    }
  }

  //removing keywords
  removeKeywordTwo() {
    this.keywordForm.patchValue({
      keyword2: "",
    });
    this.addConditionField_1 = false;
  }
  removeKeywordThree() {
    this.keywordForm.patchValue({
      keyword3: "",
    });
    this.addConditionField_2 = false;
  }
  //check if valid url
  isURL(str) {
    const urlRegex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
    this.flag = urlRegex.test(str);
  }

  getReactionType() {
    this.common.reactionType().subscribe((res) => {
      this.reactionArray = res.body;
    });
  }
}
