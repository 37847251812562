import { Injectable, OnInit } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { BillingService } from "../../pages/billing/billing.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, OnInit {
  token;

  constructor(private billing: BillingService, private router: Router) {}

  ngOnInit(): void {
    this.token = localStorage.getItem("susbcribe");
  }
  canActivate(): boolean {
    //Check if its user is subscribed
    const check = this.billing.isSubscribe();
    if (check) {
      return true;
    } else {
      this.router.navigate(["plan"]);
      return false;
    }
  }
}
