// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// Firebase configuration: The following values are required to connect your application to Firebase.
// These credentials should be kept secret and configured differently for development and production environments.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBQb3IoFIllvCL8QpreXGSn6KuGZOnCcWE",
    authDomain: "triggify-859f1.firebaseapp.com",
    projectId: "triggify-859f1",
    storageBucket: "triggify-859f1.appspot.com",
    messagingSenderId: "36083681883",
    appId: "1:36083681883:web:90d8342125dd6eaabb8d7e",
  },
  mixPanelToken: "65b00f14201ea910a6cb9e6ff2eb7083",
  socialAuthConfig: {
    googleClientId:
      "462870305342-0mjrm1qp0qvl7982kbo80rk58ujvasjs.apps.googleusercontent.com", // Google Client ID for authentication
  },
};
