import { AbstractControl, ValidatorFn, ValidationErrors } from "@angular/forms";
/**
 * Custom Angular validator that disallows URL input.
 *
 * This validator checks if the control's value matches a URL pattern (http, https, or ftp).
 * If the value is a URL, it returns an error object with the key 'urlNotAllowed'.
 * If the value is not a URL, it returns null (indicating no error).
 *
 * @returns A ValidatorFn function that performs the URL check on the given control.
 */

export function noUrlValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const urlPattern = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

    if (control.value && urlPattern.test(control.value)) {
      return { urlNotAllowed: true };
    }

    return null;
  };
}
