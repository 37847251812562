import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BillingService } from "../../pages/billing/billing.service";
import { AuthService } from "../auth.service";
import * as uuid from "uuid";
import { MixpanelService } from "../../shared/service/mixpanel.service";
import * as mixpanel from "mixpanel-browser";
import { AgencyService } from "../../pages/agency/agency.service";

declare let pendo: any;
declare let analytics: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  showSignInPage: boolean = true;
  inputType = "password";
  googleLoginToken = "";
  clientId =
    "427964124465-lee6i48l5pphhovmorqk318bbppehgmi.apps.googleusercontent.com";
  clinetSec = "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";
  buttonSpinner = false;

  constructor(
    private billing: BillingService,
    private route: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    private mixPanelservice: MixpanelService,
    private agency: AgencyService
  ) {}

  ngOnInit(): void {
    //Clearing localstorage values
    const keysToRemove = [
      "authInfo",
      "token",
      "client_secret",
      "publishable_key",
      "sub_type",
      "amount",
      "plan_type",
      "plan_interval",
      "headerHeading",
      "from_page",
      "is_google_login",
      "edit_trugger",
      "first_step",
      "susbcribe",
      "isNewUser",
      "profileUpdationFlag",
      "trigger_Name",
      "linkedinId",
      "email",
      "agency",
    ];
    keysToRemove.forEach((key) => localStorage.removeItem(key));

    //Logic to get accessToken value & auto login when user is redirected from google auth consent screen
    let params = {};
    let regex = /([^&=]+)=([^&]*)/g,
      m;
    while ((m = regex.exec(location.href))) {
      params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
    }
    if (Object.keys(params).length > 0) {
      localStorage.setItem("authInfo", JSON.stringify(params));
      let info = JSON.parse(localStorage.getItem("authInfo"));
      this.googleLoginToken = info.access_token;
      window.history.pushState({}, document.title, "" + "login");
      if (this.googleLoginToken.length > 0) {
        this.signInWithGoogle();
      }
    }
  }

  //Formgroup for login form
  loginForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [
      Validators.required,
      Validators.minLength(6),
    ]),
  });

  //Formgroup for OTP form
  otpForm = new FormGroup({
    otp: new FormControl("", Validators.required),
  });

  //To toggle visibility of password
  showOrhidePassword() {
    this.inputType = this.inputType === "password" ? "text" : "password";
  }

  //Reusable function for toast notifications
  private showToast(message: string, type: "success" | "error") {
    const options = { closeButton: true, positionClass: "toast-bottom-center" };
    type === "success"
      ? this.toastr.success(message, "", options)
      : this.toastr.error(message, "", options);
  }

  //Handling routing based on user's subscription plan
  private handleSubscription() {
    this.billing.currentPlan().subscribe((response) => {
      if (response.body.message == "No subscription found") {
        this.agency.llinkedincredList().subscribe((res) => {
          if (res.body.length > 0) {
            localStorage.setItem("linkedinId", res.body[0].id);
          }
        });
        localStorage.setItem("susbcribe", "False");
        this.route.navigate(["plan"]);
      } else if (
        response.body.message.description == null ||
        response.body.message.description == "null"
      ) {
        localStorage.setItem("susbcribe", "False");
        this.route.navigate(["plan"]);
      } else {
        localStorage.setItem("sub_type", response.body.message.description);
        this.agency.llinkedincredList().subscribe((res) => {
          if (res.body.length == 0) {
            if (response.body.message.description.includes("Agency")) {
              this.route.navigate(["agency-plan"]);
              localStorage.setItem("agency", "True");
            } else {
              this.route.navigate(["plan"]);
              localStorage.setItem("susbcribe", "False");
              localStorage.setItem("headerHeading", "free");
              localStorage.setItem("first_step", "true");
              localStorage.setItem("agency", "false");
            }
          } else {
            if (response.body.message.description.includes("Agency")) {
              localStorage.setItem("agency", "True");
            } else {
              localStorage.setItem("agency", "false");
            }
            localStorage.setItem("linkedinId", res.body[0].id);
            this.route.navigate(["dashboard/view_all"]);
            localStorage.setItem("susbcribe", "True");
          }
        });
      }
    });
  }

  //Handle form submission
  onSubmit() {
    this.buttonSpinner = true;
    if (this.loginForm.value) {
      this.auth.login(this.loginForm.value).subscribe((response) => {
        this.buttonSpinner = false;
        if (response.status == 200) {
          localStorage.setItem("isNewUser", response.body.is_new_user);
          localStorage.setItem("setIntervaltimeOut", "true");
          localStorage.setItem(
            "is_google_login",
            response.body.is_google_login
          );
          this.showToast("Login successful. Welcome to Triggify!", "success");
          const myId = uuid.v4();
          analytics.identify(response.body.id, {
            email: response.body.email,
            name: response.body.name,
          });
          analytics.track("Login", {
            email: response.body.email,
          });
          pendo.initialize({
            visitor: {
              id: response.body.id,
              email: response.body.email,
              full_name: response.body.name,
              role: "User",
            },
            account: {
              id: myId,
            },
          });
          mixpanel.identify(response.body.id);
          localStorage.setItem("token", response.body.token);
          localStorage.setItem("referral_used", response.body.referral_used);
          localStorage.setItem("email", response.body.email);
          localStorage.setItem("id", response.body.id);
          localStorage.setItem("name", response.body.name);
          localStorage.setItem("uuid", myId);
          this.handleSubscription();
        } else {
          if (response.error.message == "Invalid username or password") {
            this.showToast("Email ID or Password is not correct", "error");
          }
          if (response.error.message == "user is not verified") {
            this.showToast(response.error.message, "error");
            this.showSignInPage = false;
            this.auth
              .getOTPforverification(this.loginForm.value.email)
              .subscribe((res) => {
                this.showToast(res.body.message, "success");
              });
          }
        }
      });
    }
  }

  //Verify The User email and OTP
  verifyUser() {
    this.auth
      .verifyUserEMailid(this.loginForm.value.email, this.otpForm.value.otp)
      .subscribe((res) => {
        this.mixPanelservice.track("Completed email verification", {});
        if (res.status == 200) {
          this.showToast(
            "Registration complete! Please login and continue",
            "success"
          );
          this.showSignInPage = true;
        } else {
          this.showToast(res.error.mssage, "error");
        }
      });
  }

  //toggle between login and email verification forms
  hide() {
    this.showSignInPage = true;
  }

  // Auto Login With google when google oAuth redirects from consent screen to login with query params such as access_token
  signInWithGoogle(): void {
    this.auth.signInwithGoogle(this.googleLoginToken).subscribe((response) => {
      if (response.status == 200) {
        localStorage.setItem("token", response.body.token);
        localStorage.setItem("referral_used", response.body.referral_used);
        localStorage.setItem("email", response.body.email);
        localStorage.removeItem("authInfo");
        localStorage.setItem("setIntervaltimeOut", "true");
        localStorage.setItem("isNewUser", response.body.is_new_user);
        localStorage.setItem("is_google_login", response.body.is_google_login);
        this.showToast("Login successful. Welcome to Triggify!", "success");
        analytics.identify(response.body.id, {
          email: response.body.email,
        });
        analytics.track("Login with Google", {
          email: response.body.email,
        });
        this.handleSubscription();
      } else {
        if (
          response.error.message ==
          "You have entered invalid username or password."
        ) {
          this.showToast("Email ID or Password is not correct", "error");
        }
        if (response.error.message == "user is not verified") {
          this.showToast(response.error.message, "error");

          this.showSignInPage = false;
          this.auth
            .getOTPforverification(this.loginForm.value.email)
            .subscribe((res) => {
              this.showToast(res.body.message, "success");
            });
        }
      }
    });
  }

  //On click of continue with google it redirects to google oAuth consent screen for user consent
  signInwithGoogleAPI() {
    let url = "https://accounts.google.com/o/oauth2/v2/auth";
    let form = document.createElement("form");
    form.setAttribute("method", "GET");
    form.setAttribute("Action", url);

    let params = {
      response_type: "token",
      client_id: this.clientId,
      // redirect_uri: "https://app.triggify.io/login", //prod domain
      // redirect_uri: "http://localhost:4200/login",
      redirect_uri: "https://app-dev.triggify.io/login", //test domain
      scope:
        "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid",
      state: "pass-through-value",
      include_granted_scopes: "true",
      prompt: "select_account",
    };

    for (const p in params) {
      let input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", p);
      input.setAttribute("value", params[p]);
      form.appendChild(input);
    }
    document.body.appendChild(form);
    form.submit();
  }

  //Request to resend OTP
  resendOTP() {
    this.auth
      .getOTPforverification(this.loginForm.value.email)
      .subscribe((res) => {
        this.showToast(res.body.message, "success");
      });
  }
}
