import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../auth.service";
import { BillingService } from "../../pages/billing/billing.service";
import { MixpanelService } from "../../shared/service/mixpanel.service";
import * as mixpanel from "mixpanel-browser";
import { AgencyService } from "../../pages/agency/agency.service";

declare let analytics: any;

@Component({
  selector: "App-signUp",
  templateUrl: "sign-up.component.html",
  styleUrls: ["sign-up.component.css"],
})
export class SignUpComponent implements OnInit {
  showSignInPage: boolean = true;
  inputType = "password";
  signUpForm;
  button_spinner = false;
  clientId =
    "427964124465-lee6i48l5pphhovmorqk318bbppehgmi.apps.googleusercontent.com";
  clinetSec = "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";

  otpForm = new FormGroup({
    otp: new FormControl("", Validators.required),
  });

  token;
  firstPart;
  middlePart;
  lastPart;
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private billing: BillingService,
    private mixPanelservice: MixpanelService,
    private agency: AgencyService
  ) {}

  ngOnInit(): void {
    this.getEmailParts("sshahid417@gmail.com", 1, 3, 4);
    window.scrollTo(0, 0);
    this.signUpForm = this.fb.group({
      f_name: ["", Validators.required],
      l_name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      referral_used: ["", Validators.maxLength(30)],
      checkbox: [false],
    });
  }

  //Reusable function for toast notifications
  private showToast(message: string, type: "success" | "error") {
    const options = { closeButton: true, positionClass: "toast-bottom-center" };
    type === "success"
      ? this.toastr.success(message, "", options)
      : this.toastr.error(message, "", options);
  }

  //To split email into parts and use it on UI as : {{firstPart+"***"+middlePart+"****"+lastPart}}
  getEmailParts(
    email: string,
    firstChars: number,
    middleChars: number,
    lastChars: number
  ) {
    const emailLength = email.length;

    if (firstChars + middleChars + lastChars > emailLength) {
      throw new Error("Total characters to extract exceed the email length.");
    }

    this.firstPart = email.slice(0, firstChars);
    this.middlePart = email.slice(firstChars, firstChars + middleChars);
    this.lastPart = email.slice(emailLength - lastChars, emailLength);
  }

  //Toggle visibiility of password
  showOrhidePassword() {
    this.inputType = this.inputType === "password" ? "text" : "password";
  }

  // SignUp on Submit
  onSubmit() {
    this.button_spinner = true;
    this.auth.signUp(this.signUpForm.value).subscribe((res) => {
      this.button_spinner = false;
      if (res.status == 201) {
        analytics.identify(res.body.id, {
          email: res.body.email,
          name: res.body.name,
        });
        analytics.track("Completed sign up in auth page", {
          email: this.signUpForm.value.email,
        });
        this.getEmailParts(this.signUpForm.value.email, 1, 3, 4);

        this.token = localStorage.getItem("token");
        if (this.token) {
          this.showToast("Verify Email address by entering OTP.", "success");
        }

        this.showSignInPage = false;
      } else {
        for (let error of res.error.email) {
          this.showToast(error, "error");
        }
      }
    });
  }
  //To handle form controls
  get f() {
    return this.signUpForm.controls;
  }

  // Otp verification
  onOTPsubmit() {
    this.button_spinner = true;
    this.auth
      .otpsubmission(this.otpForm.value.otp, this.signUpForm.value.email)
      .subscribe((res) => {
        localStorage.setItem("token", res.body.token);
        localStorage.setItem("referral_used", res.body.referral_used);
        mixpanel.identify(res.body.id);
        this.mixPanelservice.track("Completed sign-up", {});
        this.mixPanelservice.track("Completed email verification", {});
        this.button_spinner = false;
        analytics.track("Verified email address", {
          email: this.signUpForm.value.email,
        });
        if (res.status == 400) {
          this.showToast("Wrong or expired OTP!", "error");
        } else {
          this.showToast("Registration complete!", "success");

          localStorage.setItem("email", this.signUpForm.value.email);
          localStorage.setItem("isNewUser", res.body.is_new_user);
          localStorage.setItem("setIntervaltimeOut", "true");

          this.showToast("Login successful. Welcome to Triggify!", "success");
          this.handleSubscription();
        }
      });
  }

  //Handle routing based on subscription plan
  private handleSubscription() {
    this.billing.currentPlan().subscribe((response) => {
      if (response.body.message == "No subscription found") {
        this.agency.llinkedincredList().subscribe((res) => {
          if (res.body.length > 0) {
            localStorage.setItem("linkedinId", res.body[0].id);
          }
        });
        localStorage.setItem("susbcribe", "False");
        this.router.navigate(["plan"]);
      } else {
        localStorage.setItem("sub_type", response.body.message.description);

        this.agency.llinkedincredList().subscribe((res) => {
          console.log(res, "response");
          if (res.body.length == 0) {
            if (response.body.message.description.includes("Agency")) {
              this.router.navigate(["agency-plan"]);
              localStorage.setItem("agency", "True");
            } else {
              this.router.navigate(["plan"]);
              localStorage.setItem("susbcribe", "False");
              localStorage.setItem("headerHeading", "free");
              localStorage.setItem("first_step", "true");
              localStorage.setItem("agency", "false");
            }
          } else {
            if (response.body.message.description.includes("Agency")) {
              localStorage.setItem("agency", "True");
            } else {
              localStorage.setItem("agency", "false");
            }
            localStorage.setItem("linkedinId", res.body[0].id);
            this.router.navigate(["dashboard/view_all"]);
            localStorage.setItem("susbcribe", "True");
          }
        });
      }
    });
  }

  // Login With google
  signInwithGoogleAPI() {
    let url = "https://accounts.google.com/o/oauth2/v2/auth";
    let form = document.createElement("form");
    form.setAttribute("method", "GET");
    form.setAttribute("Action", url);

    let params = {
      response_type: "token",
      client_id: this.clientId,
      // redirect_uri: "https://app.triggify.io/login", //prod domain
      // redirect_uri: "http://localhost:4200/login",
      redirect_uri: "https://app-dev.triggify.io/login", //testing domain
      scope:
        "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid",
      state: "pass-through-value",
      include_granted_scopes: "true",
      prompt: "select_account",
    };

    for (const p in params) {
      let input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", p);
      input.setAttribute("value", params[p]);
      form.appendChild(input);
    }
    document.body.appendChild(form);
    form.submit();
  }

  //To resend OTP
  resendOTP() {
    this.auth
      .getOTPforverification(this.signUpForm.value.email)
      .subscribe((res) => {
        this.showToast(res.body.message, "success");
      });
  }
}
