import { Component } from "@angular/core";
import * as mixpanel from "mixpanel-browser";
import { environment } from "../environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  constructor() {
    this.init();
  }
  //For tracking user interactions
  init(): void {
    mixpanel.init(environment.mixPanelToken);
  }
}
