import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { ToastrModule } from "ngx-toastr";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";
import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { LoginComponent } from "./auth/login/login.component";
import { MatChipsModule } from "@angular/material/chips";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { NgxSpinnerModule } from "ngx-spinner";
import { ForgotPasswordComponent } from "./auth/forgot-password/forgot-password.component";
import { SignUpComponent } from "./auth/sign-up/sign-up.component";
import { environment } from "../environments/environment";
import { AngularFireModule } from "@angular/fire/";
import { AuthGuard } from "./shared/guard/auth.guard";
import { MatTreeModule } from "@angular/material/tree";
import {
  GoogleLoginProvider,
  SocialLoginModule,
  SocialAuthServiceConfig,
} from "angularx-social-login";
import { PlanSelectionComponent } from "./pages/stepper-plan/planselection.component";
import { StripeComponent } from "./pages/stripe/stripe.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { SubscriptionComponent } from "./pages/subscription/subscription.component";
import { AgencyComponent } from "./pages/agency/agency.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { SharedModule } from "./pages/app-linkedin-profile/shared.module";
import { BillingAdressComponent } from "./pages/billing-address/billing-address.component";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    MatMenuModule,
    MatIconModule,
    MatChipsModule,
    MatTableModule,
    MatPaginatorModule,
    NgxSpinnerModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    SocialLoginModule,
    MatTreeModule,
    NgApexchartsModule,
    NgSelectModule,
    NgbModule,
    SharedModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SignUpComponent,
    PlanSelectionComponent,
    StripeComponent,
    SubscriptionComponent,
    AgencyComponent,
    BillingAdressComponent,
  ],
  providers: [
    AuthGuard,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.socialAuthConfig.googleClientId
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
