import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { TriggerService } from "../../pages/trigger/trigger.service";
import { CommonService } from "../../pages/common.service";
import { noUrlValidator } from "../../shared/url-validator/url.validator";
import { Router } from "@angular/router";
import { BillingService } from "../../pages/billing/billing.service";
import { MixpanelService } from "../../shared/service/mixpanel.service";

declare let analytics: any;
@Component({
  selector: "app-trigger",
  templateUrl: "add-trigger.component.html",
  styleUrls: ["add-trigger.component.css"],
})
export class AddTriggerComponent implements OnInit {
  reactionArray = [];
  addConditionField_1 = false;
  addConditionField_2 = false;
  step_1 = true;
  step_2 = false;
  step_3 = false;
  step_4 = false;
  step_5 = false;
  step_reaction = false;
  step_1_complted = false;
  step_2_complted = false;
  step_3_complted = false;
  step_4_complted = false;
  step_5_complted = false;
  step_reaction_complted = false;
  stepNumer = 5;
  activityStepNumber = 3;
  flag: boolean;
  triggerIdValue;
  linkdinProfileArray = [];
  showLinkdinProfileStep = false;
  profileValue;
  selectTriggerTypeFlag = true;
  triggerTypeValue;
  profiletriggerKeywordName;
  bussinessSelectProfileArray = [];
  planType;
  firstTrigger;
  showHideActivityfeed;
  linkedinId;

  // Form declaration
  triggerNameForm = new FormGroup({
    trigger_name: new FormControl("", Validators.required),
  });
  keywordForm = new FormGroup({
    keyword: new FormControl("", [Validators.required, noUrlValidator()]),
    keyword2: new FormControl("", [noUrlValidator()]),
    keyword3: new FormControl("", [noUrlValidator()]),
  });
  profileForm = new FormGroup({
    keyword: new FormControl("", [
      Validators.required,
      Validators.pattern(
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
      ),
    ]),
    keyword2: new FormControl("", [noUrlValidator()]),
    keyword3: new FormControl("", [noUrlValidator()]),
  });
  monitorForm = new FormGroup({
    preference: new FormControl("linkedin wide", Validators.required),
  });
  activityForm = new FormGroup({
    set_activity: new FormControl(
      "Monitor matching posts",
      Validators.required
    ),
  });
  linkedInProfileForm = new FormGroup({
    select_profile: new FormControl("", Validators.required),
    actor_urn: new FormControl(""),
  });
  buttonSpinner = false;

  constructor(
    private trigger: TriggerService,
    private toastr: ToastrService,
    private common: CommonService,
    private route: Router,
    private billing: BillingService,
    private mixPanel: MixpanelService
  ) {}

  ngOnInit(): void {
    this.common.linkedinList$.subscribe((res) => {
      this.planType = localStorage.getItem("sub_type");
      this.seletedProfileListLogic(res);
    });
    this.common.linkedinProfile$.subscribe((res) => {
      this.planType = localStorage.getItem("sub_type");
      this.seletedProfilevalue(res);
    });
    this.linkedinId = localStorage.getItem("linkedinId");
    this.getSubscriptionStatus();
    this.getReactionType();
    this.trigger.showorhideModal.subscribe((res) => {
      this.linkedinId = localStorage.getItem("linkedinId");

      this.firstTrigger = localStorage.getItem("1st_trigger");
      if (res == false) {
        this.triggerNameForm.reset();
        this.selectTriggerTypeFlag = true;
        this.monitorForm.setValue({
          preference: "linkedin wide",
        });
        this.activityForm.setValue({
          set_activity: "like",
        });
        this.keywordForm.setValue({
          keyword: "",
          keyword2: "",
          keyword3: "",
        });
        if (this.linkdinProfileArray.length > 0) {
          this.linkedInProfileForm.setValue({
            select_profile: this.linkdinProfileArray[0].datatopass,
            actor_urn: "",
          });
        }
        this.step_1 = true;
        this.step_2 = false;
        this.step_3 = false;
        this.step_4 = false;
        this.step_5 = false;
        this.step_reaction = false;
        this.step_1_complted = false;
        this.step_2_complted = false;
        this.step_3_complted = false;
        this.step_4_complted = false;
        this.step_5_complted = false;
        this.step_reaction_complted = false;
        this.addConditionField_1 = false;
        this.addConditionField_2 = false;
        this.showHideActivityfeed = false;
      }
    });
    this.trigger.linkedinProfileShowHide.subscribe((res) => {
      this.linkedinId = localStorage.getItem("linkedinId");
      if (res == false) {
        this.getSubscriptionStatus();
      } else {
      }
    });
  }

  //Get currentplan status
  getSubscriptionStatus() {
    this.billing.currentPlan().subscribe((res) => {
      this.planType = res.body.message.description;
      if (
        res.body.message.description == "Business" ||
        res.body.message.description.includes("Agency")
      ) {
        this.showLinkdinProfileStep = true;
      } else {
        this.showLinkdinProfileStep = false;
      }
    });
  }

  isURL(str) {
    const urlRegex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
    this.flag = urlRegex.test(str);
  }

  addConditionKeyword() {
    this.addConditionField_1 = true;
  }

  addConditionKeywordTwo() {
    this.addConditionField_2 = true;
  }

  //Handle submissions in stepper feature
  onSubmitStep_1() {
    const url = this.profileForm.value.keyword.trim();
    const companyPattern = /www\.linkedin\.com\/company\/([\w-]+)/;
    const profilePattern = /www\.linkedin\.com\/in\/([\w-]+)/;
    let extractedName: string | null = null;
    // Check if the URL matches the company pattern
    if (companyPattern.test(url)) {
      console.log(url.match(companyPattern));
      extractedName = url.match(companyPattern)?.[1] || null;
    }
    // Check if the URL matches the personal profile pattern
    else if (profilePattern.test(url)) {
      extractedName = url.match(profilePattern)?.[1] || null;
    }
    extractedName =
      extractedName?.split("-")[0] + " " + (extractedName?.split("-")[1] || "");
    this.profiletriggerKeywordName = extractedName || "Invalid LinkedIn URL";

    this.step_1_complted = true;
    this.step_2 = true;
  }

  //Submit step 2
  onSubmitStep_2() {
    if (this.triggerTypeValue == "profile") {
      if (this.activityForm.value.set_activity == "Monitor matching posts") {
        this.showHideActivityfeed = false;
        this.activityStepNumber = 3;
        if (this.showLinkdinProfileStep) {
          this.step_2_complted = true;
          this.step_3 = true;
        } else {
          this.complete();
        }
      } else {
        this.activityStepNumber = 4;
        this.showHideActivityfeed = true;
        this.step_3_complted = false;
        this.step_reaction = true;
        this.step_2_complted = true;
        this.step_3 = false;
      }
    } else {
      this.step_2_complted = true;
      this.step_3 = true;
    }
  }

  //Submit step 3
  onSubmitStep_3() {
    this.step_3_complted = true;
    this.step_4 = true;
  }

  //Submit step 4
  onSubmitStep_4() {
    if (this.activityForm.value.set_activity == "Monitor matching posts") {
      this.showHideActivityfeed = false;
      this.stepNumer = 5;
      if (this.showLinkdinProfileStep) {
        this.step_4_complted = true;
        this.step_5 = true;
      } else {
        this.complete();
      }
    } else {
      this.stepNumer = 6;
      this.showHideActivityfeed = true;
      this.step_4_complted = true;
      this.step_reaction = true;
    }
  }

  onSubmitStepReaction() {
    if (this.triggerTypeValue == "profile") {
      if (this.showLinkdinProfileStep) {
        this.step_reaction_complted = true;
        this.step_3 = true;
      } else {
        this.complete();
      }
    } else {
      if (this.showLinkdinProfileStep) {
        this.step_reaction_complted = true;
        this.step_5 = true;
      } else {
        this.complete();
      }
    }
  }

  //To complete the step
  complete() {
    if (this.showLinkdinProfileStep) {
      let data = this.bussinessSelectProfileArray.filter((profile) =>
        profile.datatopass.includes(
          this.linkedInProfileForm.value.select_profile
        )
      );
      this.linkedInProfileForm.patchValue({
        actor_urn: data[0].actor_urn,
      });
    } else {
      this.linkedInProfileForm.patchValue({
        actor_urn: null,
      });
    }
    this.step_5_complted = true;
    this.step_5 = true;
    this.stepNumer = 5;
    this.activityStepNumber = 3;
    this.isURL(this.keywordForm.value.keyword);
    this.buttonSpinner = true;
    if (this.triggerTypeValue == "profile") {
      let bodyData = new FormData();
      bodyData.append("trigger_name", this.profiletriggerKeywordName);
      if (this.activityForm.value.set_activity == "Monitor matching posts") {
        bodyData.append("set_activity", this.activityForm.value.set_activity);
      } else {
        bodyData.append(
          "set_activity",
          this.activityForm.value.set_activity + " all posts"
        );
      }
      bodyData.append("keyword", "");
      bodyData.append("url", this.profileForm.value.keyword);
      bodyData.append("keyword2", this.profileForm.value.keyword2);
      bodyData.append("keyword3", this.profileForm.value.keyword3);
      bodyData.append("preference", "linkedin wide");
      bodyData.append(
        "select_profile",
        this.linkedInProfileForm.value.select_profile
      );
      bodyData.append("actor_urn", this.linkedInProfileForm.value.actor_urn);
      bodyData.append("linkedin", this.linkedinId);
      this.trigger.addTrigger(bodyData).subscribe((res) => {
        this.buttonSpinner = false;
        if (res.status == 200) {
          this.mixPanel.track("Successfully added a trigger", {
            trigger_Name: this.triggerNameForm.value.trigger_name,
          });
          if (this.firstTrigger == "true") {
            analytics.track("Successfully added first trigger", {});
          }
          this.triggerNameForm.reset();
          this.profileForm.setValue({
            keyword: "",
            keyword2: "",
            keyword3: "",
          });
          this.monitorForm.reset();
          this.activityForm.reset();
          this.step_1 = true;
          this.step_2 = false;
          this.step_3 = false;
          this.step_4 = false;
          this.step_5 = false;
          this.step_reaction = false;
          this.step_1_complted = false;
          this.step_2_complted = false;
          this.step_3_complted = false;
          this.step_4_complted = false;
          this.step_5_complted = false;
          this.step_reaction_complted = false;
          this.showHideActivityfeed = false;
          this.trigger.showorhideModal.next(false);
          this.route.navigate(["dashboard/view_all"]);
        } else {
          this.triggerNameForm.reset();
          this.profileForm.setValue({
            keyword: "",
            keyword2: "",
            keyword3: "",
          });
          this.monitorForm.reset();
          this.activityForm.reset();
          this.step_1 = true;
          this.step_2 = false;
          this.step_3 = false;
          this.step_4 = false;
          this.step_reaction = false;
          this.step_5 = false;
          this.step_1_complted = false;
          this.step_2_complted = false;
          this.step_3_complted = false;
          this.step_4_complted = false;
          this.step_5_complted = false;
          this.step_reaction_complted = false;
          this.showHideActivityfeed = false;
          if (
            res.error.message.includes(
              "Exception- duplicate key value violates unique"
            )
          ) {
            this.toastr.error(
              "You already have an active trigger with this name. Please change the trigger name to activate it.",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
          } else {
            this.toastr.error(res.error.message, "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
          }
          this.trigger.showorhideModal.next(false);
        }
      });
    } else {
      let bodyData = new FormData();
      bodyData.append("trigger_name", this.triggerNameForm.value.trigger_name);
      if (this.activityForm.value.set_activity == "Monitor matching posts") {
        bodyData.append("set_activity", this.activityForm.value.set_activity);
      } else {
        bodyData.append(
          "set_activity",
          this.activityForm.value.set_activity + " all posts"
        );
      }
      bodyData.append("keyword", this.keywordForm.value.keyword);
      bodyData.append("url", "");
      bodyData.append("keyword2", this.keywordForm.value.keyword2);
      bodyData.append("keyword3", this.keywordForm.value.keyword3);
      bodyData.append("preference", this.monitorForm.value.preference);
      bodyData.append(
        "select_profile",
        this.linkedInProfileForm.value.select_profile
      );
      bodyData.append("actor_urn", this.linkedInProfileForm.value.actor_urn);
      bodyData.append("linkedin", this.linkedinId);
      this.trigger.addTrigger(bodyData).subscribe((res) => {
        if (res.status == 200) {
          if (this.firstTrigger == "true") {
            analytics.track("Successfully added first trigger", {});
          }
          this.mixPanel.track("Successfully added a trigger", {
            trigger_Name: this.triggerNameForm.value.trigger_name,
          });
          this.triggerNameForm.reset();
          this.buttonSpinner = false;
          this.keywordForm.setValue({
            keyword: "",
            keyword2: "",
            keyword3: "",
          });
          this.monitorForm.reset();
          this.activityForm.reset();
          this.step_1 = true;
          this.step_2 = false;
          this.step_3 = false;
          this.step_4 = false;
          this.step_reaction = false;
          this.step_5 = false;
          this.step_1_complted = false;
          this.step_2_complted = false;
          this.step_3_complted = false;
          this.step_4_complted = false;
          this.step_reaction_complted = false;
          this.step_5_complted = false;
          this.trigger.showorhideModal.next(false);
          this.route.navigate(["dashboard/view_all"]);
        } else {
          this.triggerNameForm.reset();
          this.keywordForm.setValue({
            keyword: "",
            keyword2: "",
            keyword3: "",
          });
          this.monitorForm.reset();
          this.activityForm.reset();
          this.step_1 = true;
          this.step_2 = false;
          this.step_3 = false;
          this.step_4 = false;
          this.step_reaction = false;
          this.step_5 = false;
          this.step_1_complted = false;
          this.step_2_complted = false;
          this.step_3_complted = false;
          this.step_4_complted = false;
          this.step_reaction_complted = false;
          this.step_5_complted = false;
          if (
            res.error.message.includes(
              "Exception- duplicate key value violates unique"
            )
          ) {
            this.toastr.error(
              "You already have an active trigger with this name. Please change the trigger name to activate it.",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
          } else {
            this.toastr.error(res.error.message, "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
          }
          this.trigger.showorhideModal.next(false);
        }
      });
    }
  }

  //methods for getting back in the stepper feature
  onBack_2() {
    this.step_2 = false;
    this.step_1 = true;
    this.step_1_complted = false;
  }

  onBack_3() {
    if (this.triggerTypeValue == "keyword") {
      this.step_3 = false;
      this.step_2 = true;
      this.step_2_complted = false;
    } else {
      if (this.showHideActivityfeed) {
        this.step_3 = false;
        this.step_reaction = true;
        this.step_reaction_complted = false;
      } else {
        this.step_3 = false;
        this.step_2 = true;
        this.step_2_complted = false;
      }
    }
  }

  onBack_4() {
    this.step_4 = false;
    this.step_3 = true;
    this.step_3_complted = false;
  }

  onBack_activity() {
    if (this.triggerTypeValue == "profile") {
      this.step_reaction = false;
      this.step_2 = true;
      this.step_2_complted = false;
      this.activityForm.setValue({
        set_activity: "like",
      });
    } else {
      this.step_reaction = false;
      this.step_4 = true;
      this.step_4_complted = false;
      this.activityForm.setValue({
        set_activity: "like",
      });
    }
  }

  onBack_5() {
    if (this.showHideActivityfeed) {
      this.step_5 = false;
      this.step_reaction = true;
      this.step_reaction_complted = false;
    } else {
      this.step_5 = false;
      this.step_4 = true;
      this.step_4_complted = false;
    }
  }

  //methods for removing keyword
  removeKeywordTwo() {
    this.keywordForm.patchValue({
      keyword2: "",
    });
    this.addConditionField_1 = false;
  }
  removeKeywordThree() {
    this.keywordForm.patchValue({
      keyword3: "",
    });
    this.addConditionField_2 = false;
  }

  //Get linkdin provile details
  getLinkedinProfile() {
    this.linkedinId = localStorage.getItem("linkedinId");

    this.bussinessSelectProfileArray = [];
    this.linkdinProfileArray = [];
    this.common.getSelectProfileOption(this.linkedinId).subscribe((res) => {
      this.seletedProfileListLogic(res);
      this.common.getLinkDinProfile(this.linkedinId).subscribe((res) => {
        this.seletedProfilevalue(res);
      });
    });
  }

  // Linkedin Profile list
  seletedProfileListLogic(res) {
    this.linkdinProfileArray = [];
    for (let index = 0; index < res.body.length; index++) {
      const arr = res.body[index].name.split("_", 3);
      this.linkdinProfileArray.push({
        name: arr[2],
        datatopass: res.body[index].name,
        actor_urn: res.body[index].actor_urn,
      });
    }
  }

  //select linkedin profile
  seletedProfilevalue(res) {
    this.bussinessSelectProfileArray = [];
    if (this.planType == "Business" || this.planType.includes("Agency")) {
      this.bussinessSelectProfileArray.push(this.linkdinProfileArray[0]);
      this.profileValue = res.body.profile;
      if (res.body.profile.includes("_personalprofile_") == false) {
        let name = res.body.profile.replace("_companyprofile_", "");
        this.bussinessSelectProfileArray.push({
          name: name,
          datatopass: res.body.profile,
          actor_urn: res.body.actor_urn,
        });
      }

      this.linkedInProfileForm.setValue({
        select_profile: this.bussinessSelectProfileArray[0].datatopass,
        actor_urn: this.bussinessSelectProfileArray[0].actor_urn,
      });
    } else {
      this.linkedInProfileForm.setValue({
        select_profile: res.body.profile,
        actor_urn: res.body.actor_urn,
      });
    }
  }

  // Hanlde trigger Type (profile/keyword) triggers
  triggerType(type) {
    this.triggerTypeValue = type;
    this.selectTriggerTypeFlag = false;
    let heading = `Add ${type} trigger`;
    this.trigger.modalHeader.next(heading);
  }

  //get reaction type array
  getReactionType() {
    this.common.reactionType().subscribe((res) => {
      this.reactionArray = res.body;
    });
  }
}
