import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject } from "rxjs";
import { TriggifyService } from "../../shared/service/triggify.service";

@Injectable({
  providedIn: "root",
})
export class TriggerService {
  flag: boolean;
  showorhideModal = new Subject<boolean>();
  linkedinProfileShowHide = new Subject<boolean>();
  linkedinConnectionStatus = new Subject<boolean>();
  modalHeader = new Subject<string>();

  constructor(
    private triggify: TriggifyService,
    private toastr: ToastrService
  ) {}

  isURL(str) {
    const urlRegex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
    this.flag = urlRegex.test(str);
  }

  createKeyword(keyWordValue) {
    this.isURL(keyWordValue);

    if (this.flag == true) {
      let bodyData = new FormData();
      bodyData.append("url", keyWordValue);

      return new Observable<any>((Observe) => {
        this.triggify
          .sendRequest(
            "POST",
            "/api/account/add-url-details",
            bodyData,
            null,
            null,
            null,
            false
          )
          .subscribe((response) => {
            if (response.status == 200) {
              this.toastr.success("Profile Added!", "", {
                closeButton: true,
                positionClass: "toast-bottom-center",
              });
              Observe.next(response);
            } else {
              Observe.next(response);
            }
          });
      });
    } else {
      let bodyData = new FormData();
      bodyData.append("keyword", keyWordValue);

      return new Observable<any>((Observe) => {
        this.triggify
          .sendRequest(
            "POST",
            "/api/account/add-keyword-details",
            bodyData,
            null,
            null,
            null,
            false
          )
          .subscribe((response) => {
            if (response.status == 200) {
              this.toastr.success("Keyword Added!", "", {
                closeButton: true,
                positionClass: "toast-bottom-center",
              });
              Observe.next(response);
            } else {
              Observe.next(response);
            }
          });
      });
    }
  }

  addTrigger(bodyData) {
    console.log(bodyData, "Form Data");
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          "/api/account/add-triggers",
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          if (response.status == 200) {
            this.toastr.success("Trigger Added!", "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
            Observe.next(response);
          } else {
            Observe.next(response);
          }
        });
    });
  }

  triggerList(id) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          `/api/account/triggers-list?linkedin=${id}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  updateTriggerData(bodyData) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "PATCH",
          "/api/account/add-triggers",
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  onGetinvidualTrigger(id) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          `/api/account/add-triggers?id=${id}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }
}
