import { Injectable, OnInit } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { BillingService } from "../../pages/billing/billing.service";

@Injectable({
  providedIn: "root",
})
export class AgencyGuard implements CanActivate, OnInit {
  token;

  constructor(private billing: BillingService, private roter: Router) {}

  ngOnInit(): void {
    this.token = localStorage.getItem("");
  }
  canActivate(): boolean {
    //Check if its agency plan
    var check = this.billing.isAgency();
    if (check) {
      return true;
    } else {
      this.roter.navigate(["billing"]);
      return false;
    }
  }
}
