import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { TriggifyService } from "../../shared/service/triggify.service";
import { CommonService } from "../common.service";

@Injectable({
  providedIn: "root",
})
export class AgencyService {
  editCompanyDetails = new BehaviorSubject<boolean>(false);
  linkdinId = new BehaviorSubject<boolean>(null);
  private companyformDataSubject = new BehaviorSubject<any>(null);
  companyformData$ = this.companyformDataSubject.asObservable();
  private billingformDataSubject = new BehaviorSubject<any>(null);
  billingformData$ = this.billingformDataSubject.asObservable();
  private linkdinformDataSubject = new BehaviorSubject<any>(null);
  linkedinformData$ = this.linkdinformDataSubject.asObservable();

  constructor(
    private triggify: TriggifyService,
    private common: CommonService
  ) {}

  updateFormData(id) {
    this.getresponseEmail(id).subscribe((res) => {
      console.log(res, "eamil");
      this.companyformDataSubject.next(res.body[0]);
    });
    this.getLinkdeinCred(id).subscribe((res) => {
      console.log(res, "link");
      this.linkdinformDataSubject.next(res.body[0]);
    });

    this.common.getBillingDetails(id).subscribe((res) => {
      console.log(res, "bill");
      this.billingformDataSubject.next(res.body.message.billing_details);
    });
  }

  createAgency(bodyData) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          "/api/account/create-agency",
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  agencydetails() {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          "/api/account/get-update-agency",
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  llinkedincredList() {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          "/api/account/add-linkedin",
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  createCompany(bodyData) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          "/api/account/get-create/email",
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  getresponseEmail(linkdinId) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "Get",
          `/api/account/get-update-delete/email/${linkdinId}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  getLinkdeinCred(linkdinId) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "Get",
          `/api/account/get-update-delete/linkedin/${linkdinId}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  updatecompanyresponse(bodyData, linkdinId) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "PATCH",
          `/api/account/get-update-delete/email/${linkdinId}`,
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  agencyUpdate(bodyData) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "PATCH",
          `/api/account/get-update-agency`,
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  deleteLinkedin(id) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "DELETE",
          `/api/account/get-update-delete/linkedin/${id}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }
}
