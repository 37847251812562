import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { BillingService } from "../billing/billing.service";
import { CommonService } from "../common.service";
declare let pendo: any;

@Component({
  selector: "app-billing-address",
  templateUrl: "billing-address.component.html",
  styleUrls: ["billing-address.component.css"],
})
export class BillingAdressComponent implements OnInit {
  constructor(
    private billing: BillingService,
    private router: Router,
    private common: CommonService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
  }
  addorUpdate: boolean = false;

  editDisable: boolean;
  navigateToUrl = true;
  cardAddeddetails = false;
  allCountryList = [];
  email;
  id;
  name;
  uuid;
  sub_type;

  ngOnInit(): void {
    this.sub_type = localStorage.getItem("sub_type");
    this.getcountryList();
    this.currentPlan();
    this.cardAdded();
    this.billingDetails();
  }

  billingForm = new FormGroup({
    f_name: new FormControl("", Validators.required),
    l_name: new FormControl("", Validators.required),
    line1: new FormControl("", Validators.required),
    line2: new FormControl(""),
    city: new FormControl("", Validators.required),
    state: new FormControl("", Validators.required),
    country: new FormControl("", Validators.required),
    postal_code: new FormControl("", Validators.required),
  });

  //Reusable function for toast notifications
  private showToast(message: string, type: "success" | "error") {
    const options = { closeButton: true, positionClass: "toast-bottom-center" };
    type === "success"
      ? this.toastr.success(message, "", options)
      : this.toastr.error(message, "", options);
  }

  //Get all country list
  getcountryList() {
    this.common.getAllCountry().subscribe((res) => {
      if (res.status == 200) {
        this.allCountryList = res.body.message.country_code;
      }
    });
  }

  //Submit billing form
  onSubmit() {
    this.spinner.show();
    this.common.onCreateBill(this.billingForm.value).subscribe((res) => {
      this.spinner.hide();
      if (res.status == 200) {
        this.showToast(res.body.message, "success");
        if (this.navigateToUrl == true) {
          if (this.sub_type == "Zero") {
            this.billing
              .oncreate(this.sub_type, "abcd", "year")
              .subscribe((res) => {
                if (res.status == 200) {
                  this.showToast(res.body.message, "success");

                  localStorage.setItem("susbcribe", "True");
                  this.email = localStorage.getItem("email");
                  pendo.initialize({
                    visitor: {
                      id: this.id,
                      email: this.email,
                      full_name: this.name,
                      role: "User",
                    },
                    account: {
                      id: this.uuid,
                    },
                  });
                  setTimeout(() => {
                    this.router.navigate(["home"]);
                  }, 3000);
                } else {
                  this.showToast(
                    "something went wrong! Please try again",
                    "error"
                  );

                  this.router.navigate(["billing"]);
                }
              });
          } else {
            this.billing.onAddcard().subscribe((res) => {
              this.spinner.hide();
              console.log(res, "card added response");
              this.billing.stripeCode.next(res);
              this.billing.clint_token.next(res.body.message.publishable_key);
              this.billing.clinet_secret.next(res.body.message.client_secret);
              localStorage.setItem(
                "publishable_key",
                res.body.message.publishable_key
              );
              localStorage.setItem(
                "client_secret",
                res.body.message.client_secret
              );
              this.router.navigate(["stripe/add-card"]);
            });
          }
        } else {
          this.email = localStorage.getItem("email");
          this.id = localStorage.getItem("id");
          this.uuid = localStorage.getItem("uuid");
          this.name = localStorage.getItem("name");
          pendo.initialize({
            visitor: {
              id: this.uuid,
              email: this.email,
              full_name: this.name,
              role: "User",
            },
            account: {
              id: this.id,
            },
          });
          localStorage.setItem("susbcribe", "True");
          this.router.navigate(["home"]);
        }
      } else {
        this.showToast(res.error.message, "error");
      }
    });
  }

  //Get billing details
  billingDetails() {
    this.common.getBillingDetails(123).subscribe((res) => {
      this.spinner.hide();

      if (res.status == 200) {
        let array = res.body.message.billing_details.name.split(" ");
        const theRemovedElement = array.shift();
        this.addorUpdate = true;
        this.billingForm.disable();
        this.editDisable = true;
        this.billingForm.setValue({
          f_name: theRemovedElement,
          l_name: array.join(" "),
          line1: res.body.message.billing_details.line1,
          line2: res.body.message.billing_details.line2,
          city: res.body.message.billing_details.city,
          country: res.body.message.billing_details.country,
          postal_code: res.body.message.billing_details.postal_code,
          state: res.body.message.billing_details.state,
        });
      } else {
        this.addorUpdate = false;
      }
    });
  }

  //Edit billing form
  edit() {
    this.editDisable = false;
    this.billingForm.enable();
  }

  //Update billing details
  update() {
    this.spinner.show();
    this.common.onUpdateBill(this.billingForm.value, 123).subscribe((res) => {
      this.spinner.hide();
      if (res.status == 200) {
        this.showToast("Billing details updated!!!", "success");

        this.editDisable = true;
        this.billingForm.disable();
      } else {
        this.showToast(res.error.message, "error");
      }
    });
  }

  //Get payment details
  paymentDetailsBtn() {
    this.spinner.show();
    this.common.getPaymentDetails().subscribe((res) => {
      this.spinner.hide();
      if (res.status == 200) {
        window.open(res.body.message.portal_url, "_self");
      }
    });
  }

  //Get current plan details
  currentPlan() {
    this.billing.currentPlan().subscribe((res) => {
      console.log(res);
      if (res.body.message == "No subscription found") {
        localStorage.setItem("susbcribe", "False");
      } else {
        if (res.body.message.description == "Free Basic Plan") {
          this.navigateToUrl = false;
        }
      }
    });
  }

  //Get card added status
  cardAdded() {
    this.billing.cardAddedorNot().subscribe((res) => {
      if (res.status == 200) {
        this.cardAddeddetails = true;
      }
    });
  }
}
