import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { BillingService } from "../billing/billing.service";
import { CommonService } from "../common.service";
declare let pendo: any;

@Component({
  selector: "app-subscription",
  templateUrl: "subscription.component.html",
  styleUrls: ["subscription.component.css"],
})
export class SubscriptionComponent implements OnInit {
  sub_type;
  plan_type;
  email;
  id;
  uuid;
  name;
  plan_interval;
  agencyPlan;
  linkedinId;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private billing: BillingService,
    private spinner: NgxSpinnerService,
    private common: CommonService
  ) {
    this.spinner.show();
  }

  ngOnInit(): void {
    this.linkedinId = localStorage.getItem("linkedinId");
    this.email = localStorage.getItem("email");
    this.id = localStorage.getItem("id");
    this.name = localStorage.getItem("name");
    this.uuid = localStorage.getItem("uuid");
    this.sub_type = localStorage.getItem("sub_type");
    this.plan_type = localStorage.getItem("plan_type");
    this.plan_interval = localStorage.getItem("plan_interval");
    this.agencyPlan = localStorage.getItem("agencyPlan");
    setTimeout(() => {
      this.createSubscription();
    }, 5000);
  }
  
  //Create a subscription (has multiple conditions according to user scenario)
  createSubscription() {
    this.billing.currentPlan().subscribe((res) => {
      if (res.body.message != "No subscription found") {
        if (res.body.message.ended_on == "Life time free") {
          this.billing
            .oncreate(this.sub_type, this.plan_type, this.plan_interval)
            .subscribe((res) => {
              console.log(res, "sub response");

              if (res.status == 200) {
                if (typeof res.body.message === "string") {
                  this.toastr.success(res.body.message, "", {
                    closeButton: true,
                    positionClass: "toast-bottom-center",
                  });
                  localStorage.setItem("susbcribe", "True");
                  localStorage.setItem("headerHeading", "notfree");
                  pendo.initialize({
                    visitor: {
                      id: this.id,
                      email: this.email,
                      full_name: this.name,
                      role: "User",
                    },
                    account: {
                      id: this.uuid,
                    },
                  });
                  setTimeout(() => {
                    if (this.agencyPlan == "false") {
                      this.router.navigate(["settings"]);
                      localStorage.setItem("agency", "false");
                    } else {
                      this.spinner.hide();
                      localStorage.setItem("agency", "True");
                      this.router.navigate(["agency-plan"]);
                    }
                  }, 3000);
                } else {
                  window.open(res.body.message.checkout_url, "_self");
                }
              } else {
                if (
                  res.error.message ==
                  "please add payment method for subscription"
                ) {
                  this.toastr.error(res.error.message, "", {
                    closeButton: true,
                    positionClass: "toast-bottom-center",
                  });
                  this.billing.onAddcard().subscribe((res) => {
                    console.log(res, "card added response");
                    this.billing.stripeCode.next(res);
                    this.billing.clint_token.next(
                      res.body.message.publishable_key
                    );
                    this.billing.clinet_secret.next(
                      res.body.message.client_secret
                    );
                    localStorage.setItem("from_page", "plan");
                    localStorage.setItem(
                      "publishable_key",
                      res.body.message.publishable_key
                    );
                    localStorage.setItem(
                      "client_secret",
                      res.body.message.client_secret
                    );
                    this.router.navigate(["stripe/add-card"]);
                  });
                } else {
                  this.toastr.error(
                    "something went wrong! Please try again",
                    "",
                    {
                      closeButton: true,
                      positionClass: "toast-bottom-center",
                    }
                  );
                  this.router.navigate(["plan"]);
                }
              }
            });
        } else if (res.body.message.payment_status == "payment failed") {
          this.billing
            .oncreate(this.sub_type, this.plan_type, this.plan_interval)
            .subscribe((res) => {
              if (res.status == 200) {
                if (typeof res.body.message === "string") {
                  this.toastr.success(res.body.message, "", {
                    closeButton: true,
                    positionClass: "toast-bottom-center",
                  });
                  localStorage.setItem("susbcribe", "True");
                  localStorage.setItem("headerHeading", "notfree");
                  pendo.initialize({
                    visitor: {
                      id: this.id,
                      email: this.email,
                      full_name: this.name,
                      role: "User",
                    },
                    account: {
                      id: this.uuid,
                    },
                  });
                  setTimeout(() => {
                    if (this.agencyPlan == "false") {
                      localStorage.setItem("agency", "false");
                      this.router.navigate(["settings"]);
                    } else {
                      this.spinner.hide();
                      localStorage.setItem("agency", "True");
                      this.router.navigate(["agency-plan"]);
                    }
                  }, 3000);
                } else {
                  window.open(res.body.message.checkout_url, "_self");
                }
              } else if (
                res.error.message ==
                "create subscription error please check subscription status for payment failed"
              ) {
                this.toastr.error(
                  "Your payment is failed due to the amout is not deducted from your card.Please check your card for balance or update the card from stripe customer portal",
                  "",
                  {
                    closeButton: true,
                    timeOut: 0,
                    extendedTimeOut: 0,
                    positionClass: "toast-bottom-center",
                  }
                );
                this.common.getPaymentDetails().subscribe((res) => {
                  this.spinner.hide();
                  if (res.status == 200) {
                    window.open(res.body.message.portal_url, "_blank");
                  }
                });
                this.router.navigate(["plan"]);
              } else {
                if (
                  res.error.message ==
                  "please add payment method for subscription"
                ) {
                  this.toastr.error(res.error.message, "", {
                    closeButton: true,
                    positionClass: "toast-bottom-center",
                  });
                  this.billing.onAddcard().subscribe((res) => {
                    this.billing.stripeCode.next(res);
                    this.billing.clint_token.next(
                      res.body.message.publishable_key
                    );
                    this.billing.clinet_secret.next(
                      res.body.message.client_secret
                    );
                    localStorage.setItem("from_page", "plan");
                    localStorage.setItem(
                      "publishable_key",
                      res.body.message.publishable_key
                    );
                    localStorage.setItem(
                      "client_secret",
                      res.body.message.client_secret
                    );
                    this.router.navigate(["stripe/add-card"]);
                  });
                } else {
                  this.toastr.error(
                    "something went wrong! Please try again",
                    "",
                    {
                      closeButton: true,
                      positionClass: "toast-bottom-center",
                    }
                  );
                  this.router.navigate(["plan"]);
                }
              }
            });
        } else {
          if (this.agencyPlan == "false") {
            this.router.navigate(["settings"]);
            localStorage.setItem("agency", "false");
          } else {
            this.spinner.hide();
            this.router.navigate(["agency-plan"]);
            localStorage.setItem("agency", "True");
          }
        }
      } else {
        this.billing
          .oncreate(this.sub_type, this.plan_type, this.plan_interval)
          .subscribe((res) => {
            if (res.status == 200) {
              if (typeof res.body.message === "string") {
                this.toastr.success(res.body.message, "", {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                });
                localStorage.setItem("susbcribe", "True");
                localStorage.setItem("headerHeading", "notfree");
                pendo.initialize({
                  visitor: {
                    id: this.id,
                    email: this.email,
                    full_name: this.name,
                    role: "User",
                  },
                  account: {
                    id: this.uuid,
                  },
                });
                setTimeout(() => {
                  if (this.agencyPlan == "false") {
                    localStorage.setItem("agency", "false");
                    this.router.navigate(["settings"]);
                  } else {
                    this.spinner.hide();
                    localStorage.setItem("agency", "True");
                    this.router.navigate(["agency-plan"]);
                  }
                }, 3000);
              } else {
                window.open(res.body.message.checkout_url, "_self");
              }
            } else {
              if (
                res.error.message ==
                "please add payment method for subscription"
              ) {
                this.toastr.error(res.error.message, "", {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                });
                this.billing.onAddcard().subscribe((res) => {
                  this.billing.stripeCode.next(res);
                  this.billing.clint_token.next(
                    res.body.message.publishable_key
                  );
                  this.billing.clinet_secret.next(
                    res.body.message.client_secret
                  );
                  localStorage.setItem("from_page", "plan");
                  localStorage.setItem(
                    "publishable_key",
                    res.body.message.publishable_key
                  );
                  localStorage.setItem(
                    "client_secret",
                    res.body.message.client_secret
                  );
                  this.router.navigate(["stripe/add-card"]);
                });
              } else {
                this.toastr.error(
                  "something went wrong! Please try again",
                  "",
                  {
                    closeButton: true,
                    positionClass: "toast-bottom-center",
                  }
                );
                this.router.navigate(["plan"]);
              }
            }
          });
      }
    });
  }
}
