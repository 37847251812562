import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject } from "rxjs";
import { TriggifyService } from "../shared/service/triggify.service";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  private linkedinListSubject: Subject<any[]> = new Subject<any[]>();
  public linkedinList$: Observable<any> =
    this.linkedinListSubject.asObservable();
  private linkedinProfileSubject: Subject<any[]> = new Subject<any[]>();
  public linkedinProfile$: Observable<any> =
    this.linkedinProfileSubject.asObservable();

  private linkedinPostSubject: Subject<any[]> = new Subject<any[]>();
  public linkedinPost$: Observable<any> =
    this.linkedinPostSubject.asObservable();

  linkedinId = new Subject<any>();

  constructor(
    private triggify: TriggifyService,
    private toastr: ToastrService
  ) {}

  // Select Preference
  selectPreference(value) {
    let bodyData = new FormData();
    bodyData.append("preference", value);

    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "PUT",
          "/api/account/update/preference",
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          Observe.next(res);
        });
    });
  }
  //get list of all countries
  getAllCountry() {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          "/api/account/country-code",
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  // get keyword and profile
  getKeywords(id) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          `/api/account/triggers-list?linkedin=${id}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  // Get email Id
  getEmail(id) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          `/api/account/get-update-delete/email/${id}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  // Update Email
  update(emailId, id) {
    let bodyData = new FormData();
    bodyData.append("email", emailId.toLowerCase());

    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "PATCH",
          `/api/account/get-update-delete/email/${id}`,
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          if (res.status == 200) {
            this.toastr.success("Email updated!", "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
            Observe.next(res);
          }
        });
    });
  }

  // delete keyword
  deleteKeyword(id) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "DELETE",
          `/api/account/update-delete/keyword/${id}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          if (res.status == 200) {
            this.toastr.success("Keyword Removed!", "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
            Observe.next(res);
          }
        });
    });
  }
  //delete profile url
  deleteprofileurl(id) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "DELETE",
          `/api/account/update-delete/url/${id}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          if (res.status == 200) {
            this.toastr.success("Profile Removed!", "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
            Observe.next(res);
          }
        });
    });
  }

  // referal details
  referalDetails() {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          "/api/account/referral-details",
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          Observe.next(res);
        });
    });
  }

  // Reset Password
  onresetPassword(password) {
    let bodydata = {
      new_password: password,
    };
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          "/api/account/change-password",
          bodydata,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          Observe.next(res);
        });
    });
  }

  // get current preference
  getPreference() {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          "/api/account/update/preference",
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          Observe.next(res);
        });
    });
  }
  //get linkdein post by trigger
  getlinkedinPost(triggerName, linkedinId) {
    let bodyData = {
      trigger_name: triggerName,
      linkedin: linkedinId,
    };
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          `/api/account/linkedin-post`,
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          Observe.next(res);
        });
    });
  }
  //Get all linkedin posts for trigger
  getlinkedinViewAllPost(triggerName, linkedinId) {
    let bodyData = {
      trigger_name: triggerName,
      linkedin: linkedinId,
    };
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          `/api/account/linkedin-post`,
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          this.linkedinPostSubject.next(res);
          Observe.next(res);
        });
    });
  }
  //send linkedin cookie
  postLinkedinCookies(cookie) {
    let bodydata = {
      cookie: cookie,
    };
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          "/api/account/linkedin-cookie",
          bodydata,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          Observe.next(res);
        });
    });
  }
  //get linkedin cookie status
  cookiesstatus() {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          "/api/account/linkedin-cookie",
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          Observe.next(res);
        });
    });
  }

  // create biiling details
  onCreateBill(billingForm) {
    let bodyData = new FormData();
    // bodyData.append('name',billingForm.f_name +' ' + billingForm.l_name);
    bodyData.append("phone", "");
    bodyData.append("line1", billingForm.line1);
    bodyData.append("line2", billingForm.line2);
    bodyData.append("city", billingForm.city);
    bodyData.append("state", billingForm.state);
    bodyData.append("postal_code", billingForm.postal_code);
    bodyData.append("country", billingForm.country);

    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          "/api/account/add-billing-details",
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  // get billing details
  getBillingDetails(id) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          `/api/account/update-delete/billing-details/${id}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          Observe.next(res);
        });
    });
  }
  //update billing details
  onUpdateBill(billingForm, linkdinId) {
    let bodyData = new FormData();
    // bodyData.append('name',billingForm.f_name +' ' + billingForm.l_name);
    bodyData.append("phone", "");
    bodyData.append("line1", billingForm.line1);
    bodyData.append("line2", billingForm.line2);
    bodyData.append("city", billingForm.city);
    bodyData.append("state", billingForm.state);
    bodyData.append("postal_code", billingForm.postal_code);
    bodyData.append("country", billingForm.country);

    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "Patch",
          `/api/account/update-delete/billing-details/${linkdinId}`,
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }
  //get payment details
  getPaymentDetails() {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          "/api/subscription/customer-portal-session",
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          Observe.next(res);
        });
    });
  }
  //connect to likdein account
  onConnectToLinkedin(linkedinForm) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          "/api/account/add-linkedin",
          linkedinForm,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }
  //update linkedin accoung
  onUpdateToLinkedin(linkedinForm) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "PATCH",
          "/api/account/add-linkedin",
          linkedinForm,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  // update linkdin with id
  onUpdateToLinkedinId(linkedinForm, id) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "PUT",
          `/api/account/get-update-delete/linkedin/${id}`,
          linkedinForm,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }
  //verify linkedin pin
  linkedinPinVerify(linkedinPin) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          "/api/account/submit-linkedin-pin",
          linkedinPin,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }
  //disconnect linkedin account
  linkedinDisconnect() {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "DELETE",
          "/api/account/add-linkedin",
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }
  //get linkedin profile info
  getLinkedinProfile(id) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          `/api/account/get-update-delete/linkedin/${id}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }
  //get selected profile
  getSelectProfileOption(LlinkdinId) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          `/api/account/select-profiles?linkedin=${LlinkdinId}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          this.linkedinListSubject.next(response);
          Observe.next(response);
        });
    });
  }
  //like posts
  likeLikedinPost(id, linkedinId) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          `/api/account/post-like?post_id=${id}&linkedin=${linkedinId}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }
  //exclude specific keywords
  excludeKeyword(data) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          `/api/account/exclude-keyword`,
          data,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }
  //get list of excluded keywords
  excludedList(id) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          `/api/account/exclude-keyword?id=all&linkedin=${id}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }
  //delete excluded keywords
  deleteExcluKeyword(id) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "DELETE",
          `/api/account/exclude-keyword/${id}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }
  //get graph data(likes, posts)
  getGraphData(value, duration, id) {
    let bodyData = {
      trigger_name: value,
      duration: duration,
      linkedin: id,
    };
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          `/api/account/trigger-graph`,
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }
  //add linkedin profile
  addLinkedinProfile(value, linkdinId, actor_urn) {
    let bodyData = {
      profile: value,
      linkedin: linkdinId,
      actor_urn: actor_urn,
    };

    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          `/api/account/select-profile`,
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  getLinkDinProfile(id) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          `/api/account/select-profile?linkedin=${id}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          this.linkedinProfileSubject.next(response);
          Observe.next(response);
        });
    });
  }
  deleteLinkDinProfile(id) {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "DELETE",
          `/api/account/select-profile-delete/${id}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  //cancel subscription
  deleteSub(plan, value) {
    let bodyData = {
      plan_type: plan,
      agency_plan: value,
    };
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          `/api/subscription/cancel`,
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }
  //Get reaction type
  reactionType() {
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest("GET", `/api/account/emoji`, null, null, null, null, false)
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }
}
